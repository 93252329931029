import React, { useEffect, useState } from "react";
import { useCreditRequest } from '../Context/useCreditRequest';
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { Link, json, useNavigate } from 'react-router-dom';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import '../StyleSheets/SelfieVerification.css'
import '../StyleSheets/SelfieVerification.scss'
import Swal from 'sweetalert2'
import OlimpiaServices from "../Services/OlimpiaServices";
import withReactContent from 'sweetalert2-react-content'
import { Modal } from "react-bootstrap";
import { GenerateJWTSessionCustomer } from '../Services/SoraService';

const SelfieVerification = () => {


    const { setLogoSize } = useCreditRequest();
    const [sending, setSending] = useState(false);
    const navigate = useNavigate();
    const { getStorage, setStorage } = useCreditRequestAuth();
    const { ConsultarEstadoValidacionProceso, SendUrlToDestination } = OlimpiaServices();
    const [guid, setGuid] = useState(null);
    const [show, setShow] = useState(false);

    const [loaderClass, seLoaderClass] = useState("loading-btn--pending");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let request = getStorage("request")

    const MySwal = withReactContent(Swal);
    let timer = null;



    useEffect(() => {

        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" });

        sendUrl();

    }, []);


    const ModalError = () => {

        const [timerR, setTimerR] = useState(10);

        useEffect(() => {

            let intervalIdR = null;

            intervalIdR = setInterval(() => {
                setTimerR(timerR => timerR - 1);

            }, 1000);


            if (timerR <= 0) clearInterval(intervalIdR)
            return () => clearInterval(intervalIdR);

        }, [timerR]);

        return <>

            Opps😣lo sentimos.
            <br />
            En este momento presentamos inconvenientes con el sistema 🥺
            <br />
            Lamentamos las molestias ocasionadas.
            <br />
            <br />
            <table>
                <tbody>
                    <tr>
                        <td style={{ padding: "5px" }}><button type="button" disabled={timerR > 0} className="btn btn-mp" onClick={() => { sendUrl(); MySwal.close(); }}>Volver a intentar {timerR > 0 ? timerR : ""}  </button></td>
                        <td style={{ padding: "5px" }}><button type="button" className="btn btn-mp" onClick={() => {
                            setStorage("request", {
                                ...request,
                                state: 5,
                                allowSelfieVerification: false,
                                allowManualVerification: true
                            });
                            MySwal.close();
                            navigate("/Verification");
                        }}>Elegir otro metodo</button><br /></td>
                        <td style={{ padding: "5px" }}><button type="button" className="btn btn-mp" onClick={() => {
                            setStorage("request", {
                                ...request,
                                state: 0,
                                updateCustomer: true
                            });
                            MySwal.close();
                            navigate("/");
                        }}>Modificar datos</button></td>
                    </tr>

                </tbody>
            </table>


        </>

    }



    const sendUrl = () => {



        setSending(true);
        SendUrlToDestination(request.idCustomer, request.methodLink === 1 ? "EMA" : "SMS").then(data => {

            setSending(false);

            if (data.status !== 200) {
                MySwal.fire({
                    html: <ModalError></ModalError>,
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    showDenyButton: false,

                })


                return;
            }

            let procesoConvenioGuid = JSON.parse(data.data.data).procesoConvenioGuid


            setGuid(procesoConvenioGuid);


            timer = setInterval(() => {

                ConsultarEstadoValidacionProceso(procesoConvenioGuid).then(res => {

                    let dataval = JSON.parse(res.data.data)


                    if (res.status !== 200) {
                        MySwal.fire({
                            html: "Error al obtener olimpia",
                            icon: 'error'
                        })
                        clearInterval(timer);
                        return;
                    }

                    if (!dataval.finalizado) {
                        console.log("PENDIENTE VALIDACION OLIMPIA");
                    }
                    else if (dataval.finalizado && dataval.aprobado) {
                        seLoaderClass("loading-btn--success");
                        console.log("APROBADO VALIDACION OLIMPIA");
                        clearInterval(timer);

                    }
                    else {
                        seLoaderClass("loading-btn--fail");
                        console.log("RECHAZADO VALIDACION OLIMPIA");
                        clearInterval(timer);
                    }


                });

            }, 10 * 1000);

        });
    }


    useEffect(() => () => {
        clearInterval(timer);
    }, []);

    const submit = () => {

        setSending(true);
        ConsultarEstadoValidacionProceso(guid).then(res => {
            setSending(false);

            let dataval = JSON.parse(res.data.data)


            if (res.status !== 200) {
                MySwal.fire({
                    html: "Error en la validación de identidad",
                    icon: 'error'
                })
                return;
            }

            if (!dataval.finalizado) {
                MySwal.fire({
                    html: "Validación de identidad pendiente",
                    icon: 'info'
                })
            }
            else if (dataval.finalizado && dataval.aprobado) {

                seLoaderClass("loading-btn--success");

                // MySwal.fire({
                //     html: "Aprobado Olimpia",
                //     icon: 'success'
                // })



                GenerateJWTSessionCustomer({ token: dataval.token }).then(resjwt => {

                    if (resjwt.status === 200 || resjwt.status === 201) {

                        setStorage("request", {
                            ...request,
                            state: 9,
                            identityValidated: true
                        });

                        setStorage("creditRequestToken", JSON.parse(resjwt.data.data).Token, { path: "/" })
                        setStorage("expiredTime", Date.parse(JSON.parse(resjwt.data.data).ExpiredTime), { path: "/" })

                        navigate("/Dashboard");
                    } else {

                        MySwal.fire({
                            html: resjwt.data.message,
                            icon: 'error'
                        })
                    }

                })

            }
            else {
                seLoaderClass("loading-btn--fail");
                MySwal.fire({
                    html: "Validación de identidad fallida",
                    icon: 'error'
                })
            }

        });

    }

    const goToManualValidation = () => {
        setStorage("request", {
            ...request,
            state: 8
        });
        navigate("/ManualValidation");
    }

    const goToQuestionsVerification = () => {
        setStorage("request", {
            ...request,
            state: 13
        });
        navigate("/QuestionsVerification");
    }

    return (
        <>


            <table id="v-table">
                <tbody>

                    <tr>
                        <td style={{ textAlign: "center", color: "black", fontSize: "13px", padding: "0px" }} colSpan={2}>
                            {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}

                        </td>

                    </tr>
                    <tr>
                        <td style={{ textAlign: "center", color: "gray", fontSize: "13px", padding: "0px" }} colSpan={2}>

                            {request.cusDocumentTypeTemp}   {request.cusDocument}
                        </td>

                    </tr>
                    <tr>
                        <td style={{ textAlign: "center", color: "gray", paddingTop: "15px" }} colSpan={2}>
                            <h3><b style={{ color: "black" }}>4</b>/4</h3>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className="divText" style={{ fontSize: "15px", fontWeight: "bold" }}>
                                Por favor, no cierres esta pantalla y continua en tu celular ☎️, aquí te esperamos 🤙
                                <br />

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className="divText">
                                Te hemos enviado un enlace para que procedas a verificar tu identidad en tu dispositivo móvil. Sigue las instrucciones que ahí te indiquen y cuando termines el proceso vuelve a esta pagina y pulsa el botón de "Continuar".
                                <br />

                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={2} style={{ textAlign: "center" }}>
                            {/* <div className="lds-dual-ring"></div> */}
                            {/* <span className="loading-btn-wrapper">
                                <button className="loading-btn--pending js_fail-animation-trigger">
                                    <span className="loading-btn__text">
                                        Submit
                                    </span>
                                </button>
                            </span> */}

                            <span className="loading-btn-wrapper">
                                <button className={`loading-btn js_success-animation-trigger ${loaderClass}`}>
                                    <span className="loading-btn__text">
                                        Submit
                                    </span>
                                </button>
                            </span>




                        </td>
                    </tr>
                    <tr>

                        <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>


                            <button type="button" disabled={sending} className="btn btn-mp" onClick={submit}  >Continuar</button>
                            <br /><br />
                            <u style={{ color: "blue", cursor: "pointer" }} onClick={handleShow}>¿Tienes problemas?</u>
                            <br /><br />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {sending &&
                                (
                                    <div className="my-loader">
                                        <div className="my-loader-container">
                                            <img src={imgLoader} alt="loader"></img>

                                        </div>
                                    </div>
                                )}
                        </td>
                    </tr>
                </tbody>

            </table>


            <Modal show={show} onHide={handleClose} dialogClassName="modal-problems">
                <Modal.Header closeButton>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <i className="bi bi-question-circle" style={{ fontSize: "50px" }}></i>
                    </div>

                </Modal.Header>

                <Modal.Body>
                    <table id="problems-table">
                        <tbody>
                            <tr>
                                <td>Si estas presentando problemas a la hora de realizar el proceso de verificación de identidad te ofrecemos las siguientes alternativas:</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="divBox" onClick={goToQuestionsVerification}>

                                        <div className="container-fluid">
                                            <div className="row" >

                                                <div className="col-md-3">
                                                    <i className="bi bi-question-circle" style={{ fontSize: "35px" }}></i>
                                                </div>
                                                <div className="col-md-9">
                                                    <b>verificación por medio de preguntas de seguridad</b><br />
                                                </div>



                                            </div>
                                        </div>



                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="divBox" onClick={goToManualValidation}>

                                        <div className="container-fluid">
                                            <div className="row" >

                                                <div className="col-md-3">
                                                    <i className="bi bi-upload" style={{ fontSize: "35px" }}></i>
                                                </div>
                                                <div className="col-md-9">
                                                    <b>verificación por medio de subida de fotos manuales</b><br />
                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>


            </Modal>


        </>

    );
}

export default SelfieVerification;