import React, { useEffect, useRef, useState, createRef } from "react";
import { useCreditRequest } from "../Context/useCreditRequest";
import '../StyleSheets/RequestStepFive.css'
import useForm from "../Hooks/useForm";
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { Typeahead, Hint } from "react-bootstrap-typeahead";
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { getCountriesState } from "../Services/CountriesServices";
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import { useNavigate } from 'react-router-dom';
import TarsServices from "../Services/TarsServices";
import IntlTelInput from 'react-intl-tel-input-18';
import 'react-intl-tel-input-18/dist/main.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import CustomSelect from "./CustomSelect";
import Privacy from "./Privacy";
import PersonalData from "./PersonalData";

const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    //   integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const currencyMask = createNumberMask(defaultMaskOptions)



const RequestStepFive = () => {
    const navigate = useNavigate();
    const { getEC, getCT } = useCreditRequest();
    const { getStorage, setStorage } = useCreditRequestAuth();
    const [sending, setSending] = useState(false);
    let request = getStorage("request")


    const educationalLevelRef = createRef(null);
    const maritalStatusRef = createRef(null);
    const housingTypeRef = createRef(null);
    // const housingAntiquityRef = createRef(null);
    //  const houseValueRef = createRef(null);
    const economicLevelRef = createRef(null);
    const dependentsRef = createRef(null);
    // const assetsRef = createRef(null);
    const stateCountryRef = createRef(null);
    const residenceCityRef = createRef(null);
    const addressRef = React.useRef();
    const companyRef = React.useRef();
    const laborTypeRef = createRef(null);
    const laborContractRef = createRef(null);
    const laborAntiquityRef = createRef(null);
    const incomeRef = React.useRef();
    const expensesRef = React.useRef();
    // const laborActivityRef = createRef(null);


    const [enumCustomer, setEnumCustomer] = useState([]);
    // const [customer, setCustomer] = useState({});
    // const [departaments, setDepartaments] = useState({});
    const [educationalLevels, setEducationalLevels] = useState([]);
    const [maritalStatuses, setMaritalStatuses] = useState([]);
    const [housingTypes, setHousingTypes] = useState([]);
    const [housingAntiquities, setHousingAntiquities] = useState([]);
    // const [houseValues, setHouseValues] = useState([]);
    const [economicLevels, setEconomicLevels] = useState([]);
    const [residenceCountries, setResidenceCountries] = useState([]);
    const [stateCountries, setStateCountries] = useState([]);
    const [stateCountriesTemp, setStateCountriesTemp] = useState([]);
    const [laborTypes, setLaborTypes] = useState([]);
    const [laborActivities, setLaborActivities] = useState([]);
    const [laborContracts, setLaborContracts] = useState([]);
    const [laborAntiquities, setLaborAntiquities] = useState([]);
    const [dependents, setDependents] = useState([]);
    // const [assets, setAssets] = useState([]);
    const [municipalities, setMunicipalities] = useState([]);
    //const [personalReferences, setPersonalReferences] = useState(request.cusReferencesTemp ? request.cusReferencesTemp : [{ key: Date.now(), typeReference: "", name: "", phoneCode: [{ value: "CO", name: "57", control: "phoneCode" }], phoneCodeTemp: "co", phone: "" }]);
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [typeReferences, setTypeReferences] = useState([]);
    // const [labelHouseValue, setLabelHouseValue] = useState("");
    const [salaried, setSalaried] = useState(true);
    const [independent, setIndependent] = useState(false);
    const [otherLaborType, setOtherLaborType] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const MySwal = withReactContent(Swal);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const validateForm = (form) => {

        let errors = {};


        if (!form.dataPrivacyCheck && request.comingFromDashboard) { errors.dataPrivacyCheck = "El campo dataPrivacyCheck es requerido" }
        if (!form.dataTreatmentCheck && request.comingFromDashboard) { errors.dataTreatmentCheck = "El campo dataTreatmentCheck es requerido" }

        // form.personalReferences.forEach(element => {

        //     let errorTemp = {
        //         key: element.key
        //     }
        //     if (!element.typeReference) { errorTemp = { ...errorTemp, typeReference: "El campo Referencia es requerido" } }
        //     if (!element.name.trim()) { errorTemp = { ...errorTemp, name: "El campo Referencia es requerido" } }
        //     if (element.phoneCode.length === 0) { errorTemp = { ...errorTemp, phoneCode: "El campo Referencia es requerido" } }
        //     if (!element.phone.trim()) { errorTemp = { ...errorTemp, phone: "El campo Referencia es requerido" } }

        //     // if (!errors.personalReferences && Object.keys(errorTemp).length > 1) errors.personalReferences = []
        //     // if (Object.keys(errorTemp).length > 1)
        //     //     errors.personalReferences.push(errorTemp);
        // });


        // if (!form.laborActivity && form.laborType && (form.laborType === "ASA" || form.laborType === "FRL")) { errors.laborActivity = "El campo Actividad economica es requerido"; laborActivityRef.current.focus() }
        if (!String(form.expenses).trim()) { errors.expenses = "El campo Egresos es requerido"; expensesRef.current.inputElement.focus() }
        if (!String(form.income).trim()) { errors.income = "El campo Ingresos es requerido"; incomeRef.current.inputElement.focus() }
        if (!form.laborAntiquity) { errors.laborAntiquity = "El campo Antiguedad laboral es requerido"; laborAntiquityRef.current.focus() }
        if (!form.laborContract && form.laborType && (form.laborType === "ASA" || form.laborType === "FRL")) { errors.laborContract = "El campo Tipo de contrato es requerido"; laborContractRef.current.focus() }
        if (!form.laborType) { errors.laborType = "El campo Tipo de empleo es requerido"; laborTypeRef.current.focus() }
        if (!form.address.trim()) { errors.address = "El campo Dirección es requerido"; addressRef.current.focus() }
        if (!form.company.trim()) { errors.company = "El campo Empresa es requerido"; companyRef.current.focus() }
        if (form.stateCountry && !form.residenceCity) { errors.residenceCity = "El campo Ciudad es requerido"; residenceCityRef.current.focus() }
        if (form.residenceCountry && form.residenceCountry === "CO" && !form.stateCountry.trim()) { errors.stateCountry = "El campo Departamento de residencia requerido"; stateCountryRef.current.focus() }
        if (!form.residenceCountry.trim()) { errors.residenceCountry = "El campo Pais de residencia requerido" }
        // if (!form.assets.trim()) { errors.assets = "El campo Activos es requerido"; assetsRef.current.focus() }
        if (!form.dependents.trim()) { errors.dependents = "El campo Personas a cargo es requerido"; dependentsRef.current.focus() }
        if (!form.economicLevel.trim()) { errors.economicLevel = "El campo estrato requerido"; economicLevelRef.current.focus() }
        // if (form.housingType && !form.houseValue && (form.housingType === "OWN" || form.housingType === "REN")) { errors.houseValue = "El campo Valor vivienda es requerido"; houseValueRef.current.focus() }
        // if (!form.housingAntiquity.trim()) { errors.housingAntiquity = "El campo Años en vivienda es requerido"; housingAntiquityRef.current.focus() }
        if (!form.housingType.trim()) { errors.housingType = "El campo Tipo de vivienda es requerido"; housingTypeRef.current.focus() }
        if (!form.maritalStatus.trim()) { errors.maritalStatus = "El campo Estado civil es requerido"; maritalStatusRef.current.focus() }
        if (!form.educationalLevel.trim()) { errors.educationalLevel = "El campo Nivel de estudios es requerido"; educationalLevelRef.current.focus() }


        return errors;

    };

    const initialForm = {
        educationalLevel: request.cusEducationLevel ? request.cusEducationLevel : "",
        maritalStatus: request.cusMaritalStatus ? request.cusMaritalStatus : ""
        , housingType: request.cusHousingType ? request.cusHousingType : ""
        // , housingAntiquity: request.cusHousingAntiquity ? request.cusHousingAntiquity : ""
        // , houseValue: request.cusHouseValue ? request.cusHouseValue : ""
        , economicLevel: request.cusEconomicLevel ? request.cusEconomicLevel : ""
        , company: request.cusCompany ? request.cusCompany : ""
        , residenceCountry: request.cusAddressCountry ? request.cusAddressCountry : (request.cusDocumentType === "CCC" ? "CO" : "")
        , stateCountry: request.cusAddressState ? request.cusAddressState : ""
        , residenceCity: request.cusAddressCity ? request.cusAddressCity : ""
        , neighborhood: request.cusAddressTown ? request.cusAddressTown : ""
        , address: request.cusAddress ? request.cusAddress : ""
        , company: request.cusCompany ? request.cusCompany : ""
        , laborType: request.cusLaborType ? request.cusLaborType : ""
        // , laborActivity: request.cusLaborActivity ? request.cusLaborActivity : ""
        , laborContract: request.cusLaborContract ? request.cusLaborContract : ""
        , income: request.cusLaborSalary ? request.cusLaborSalary : ""
        , expenses: request.cusExpenses ? request.cusExpenses : "", laborAntiquity: request.cusLaborAntiquity ? request.cusLaborAntiquity : ""
        , dependents: request.cusDependents ? request.cusDependents : ""
        // , assets: request.cusAssests ? request.cusAssests : "", dataPrivacyCheck: false, dataTreatmentCheck: false
    };

    const { form, errors, handleChange, handleSubmit, handleChangeTypeahead, handleBlurTypeahead, handleChecked, handleChangeSelect } = useForm(initialForm, validateForm);
    const { newCustomer, getCustomerById } = TarsServices();

    useEffect(() => {

        setSending(true);

        let a = getEC();

        let b = getCT();

        let c = getCountriesState();

        Promise.all([a, b, c]).then((values) => {
            setSending(false);
            let data1 = values[0]

            setEnumCustomer(data1)
            setEducationalLevels(data1.filter(el => el.EnumerationId === "cusEnumEducationLevel")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "educationalLevel" } }));
            setMaritalStatuses(data1.filter(el => el.EnumerationId === "cusEnumMaritalStatus")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "maritalStatus" } }));

            setHousingTypes(data1.filter(el => el.EnumerationId === "cusEnumHousingType")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "housingType" } }));
            // setHousingAntiquities(data1.filter(el => el.EnumerationId === "cusEnumHousingAntiquity")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "housingAntiquity" } }));

            setEconomicLevels(data1.filter(el => el.EnumerationId === "cusEnumEconomicLevel")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "economicLevel" } }));

            setLaborTypes(data1.filter(el => el.EnumerationId === "cusEnumLaborType")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "laborType" } }));
            // setLaborActivities(data1.filter(el => el.EnumerationId === "cusEnumLaborActivity")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "laborActivity" } }));

            setLaborContracts(data1.filter(el => el.EnumerationId === "cusEnumLaborContract")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "laborContract" } }));

            setTypeReferences(data1.filter(el => el.EnumerationId === "cusEnumTypeReference")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "typeReference" } }));

            setLaborAntiquities(data1.filter(el => el.EnumerationId === "cusEnumLaborAntiquity")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "laborAntiquity" } }));


            // setHouseValues(data1.filter(el => el.EnumerationId === "cusEnumHousingCost")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "houseValue" } }));

            // setAssets(data1.filter(el => el.EnumerationId === "cusEnumAssetsValues")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "assets" } }));

            setDependents(data1.filter(el => el.EnumerationId === "cusEnumDependents")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "dependents" } }));



            ///////////////////////////////
            let data2 = values[1]
            setResidenceCountries(data2.map(el => { return { value: el.value, name: el.OriginalName, control: "residenceCountry" } }));
            setPhoneCodes(data2.map(el => { return { value: el.value, name: el.Phonecode, control: "phoneCode" } }));
            ////////////////////////////////////
            let data3 = values[2]
            //setDepartaments(data3)
            let sct = data3.CacheValues[0].Departments.map(el => { return { value: el.value, IdCountry: "CO", name: el.label, control: "stateCountry", Municipalities: el.Municipalities.map(m => { return { value: m.value, name: m.label, control: "residenceCity" } }) } })
            setStateCountriesTemp(sct)


            // if (request.cusDocumentType === "CCC" && (!request.cusAddressCountry || request.cusAddressCountry === "CO")) {

            //     let temp = sct.filter(item => item.IdCountry === "CO");

            //     if (temp)
            //         setStateCountries(temp);
            //     else
            //         setStateCountries([]);
            // }
            // else
            //     setStateCountries([]);

            /////////////////////////////////////////
            if (request.comingFromDashboard) {

                getCustomerById().then(res => {

                    mapRequest(JSON.parse(res.data.data), data1, data3)
                })
            }



        })



    }, []);

    // useEffect(() => {
    //     if (Object.keys(customer).length !== 0 && enumCustomer.length > 0)
    //         mapRequest(customer);

    // }, [customer, enumCustomer, departaments]);

    const mapRequest = (data, enumCustomer, departaments) => {

        handleChange({ target: { name: "address", value: data.cusAddress ?? "" } })
        handleChange({ target: { name: "company", value: data.cusCompany ?? "" } })
        handleChange({ target: { name: "income", value: data.cusLaborSalary } })
        handleChange({ target: { name: "expenses", value: data.cusTotalExpenses } })
        handleChangeSelect({ target: { name: "educationalLevel", value: data.cusEducationLevel ?? "" } })
        handleChangeSelect({ target: { name: "maritalStatus", value: data.cusMaritalStatus ?? "" } })
        handleChangeSelect({ target: { name: "housingType", value: data.cusHousingType ?? "" } })
        // handleChangeSelect({ target: { name: "housingAntiquity", value: data.cusHousingAntiquity ?? "" } })
        // handleChangeSelect({ target: { name: "houseValue", value: data.cusHousingType === "OWN" ? data.cusHouseValue : data.cusHousingType === "REN" ? data.cusHouseRent : "" } })
        handleChangeSelect({ target: { name: "economicLevel", value: data.cusEconomicLevel ?? "" } })
        handleChangeSelect({ target: { name: "dependents", value: data.cusNumDependents ?? "" } })
        // handleChangeSelect({ target: { name: "assets", value: data.cusAssetsValues ?? "" } })

        handleChangeSelect({ target: { name: "residenceCountry", value: data.cusAddressCountry ?? "" } })
        handleChangeSelect({ target: { name: "stateCountry", value: data.cusAddressState ?? "" } })
        handleChangeSelect({ target: { name: "residenceCity", value: data.cusAddressCity ?? "" } })

        handleChangeSelect({ target: { name: "laborType", value: data.cusLaborType ?? "" } })
        handleChangeSelect({ target: { name: "laborContract", value: data.cusLaborContract ?? "" } })
        handleChangeSelect({ target: { name: "laborAntiquity", value: data.cusLaborAntiquity ?? "" } })
        // handleChangeSelect({ target: { name: "laborActivity", value: data.cusLaborActivity ?? "" } })

        // setPersonalReferences(

        //     data.cusReferences.map(el => {

        //         return {
        //             key: el.Id,
        //             typeReference: el.TypeReference,// enumCustomer.filter(en => en.EnumerationId === "cusEnumTypeReference")[0].EnumerationValues.map(en => { return { value: en.value, name: en.label, control: "typeReference" } }).filter(en => en.value === el.TypeReference),
        //             name: el.FullName,
        //             phoneCode: [{ value: "CO", name: "57", control: "phoneCode" }],
        //             phoneCodeTemp: "co",
        //             phone: el.Phone
        //         }
        //     })

        // )

        //setTypeheadStateCity(data.cusAddressState, data.cusAddressCity, "stateCountry", "residenceCity", departaments);
    }

    // const setTypehead = (value, enumName, formControlName, enumCustomer) => {

    //     let temp = enumCustomer.filter(el => el.EnumerationId === enumName)[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: formControlName } }).filter(el => el.value === value)
    //     handleChangeTypeahead(temp, formControlName);

    // }

    // const setTypeheadStateCity = (state, city, formControlName1, formControlName2, departaments) => {

    //     if (Object.keys(departaments).length > 0) {
    //         let temp = departaments.CacheValues[0].Departments.map(el => { return { value: el.value, IdCountry: "CO", name: el.label, control: "stateCountry", Municipalities: el.Municipalities.map(m => { return { value: m.value, name: m.label, control: "residenceCity" } }) } }).filter(el => el.value === state)

    //         handleChangeTypeahead(temp, formControlName1);

    //         if (temp.length > 0 && temp[0].Municipalities.length > 0) {
    //             let temp2 = temp[0].Municipalities.filter(el => el.value === city)

    //             handleChangeTypeahead(temp2, formControlName2);
    //         }
    //     }


    // }

    // const initialRender = React.useRef(true);
    // useEffect(() => {
    //     if (enumCustomer.length > 0) {
    //         // if (initialRender.current && form.housingType) {
    //         //     initialRender.current = false;
    //         // }
    //         // else if (!initialRender.current && form.housingType) {

    //         //     handleChangeSelect({ target: { name: "houseValue", value: "" } })
    //         // }


    //         if (form.housingType && form.housingType === "REN") {
    //             setLabelHouseValue("Valor arriendo");
    //             setHouseValues(enumCustomer.filter(el => el.EnumerationId === "cusEnumHousingRent")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "houseValue" } }));
    //         }
    //         else if (form.housingType && form.housingType === "OWN") {
    //             setLabelHouseValue("Valor vivienda");
    //             setHouseValues(enumCustomer.filter(el => el.EnumerationId === "cusEnumHousingCost")[0].EnumerationValues.map(el => { return { value: el.value, name: el.label, control: "houseValue" } }));
    //         }
    //         else {
    //             handleChangeSelect({ target: { name: "houseValue", value: "" } })
    //             setLabelHouseValue("Valor vivienda");
    //         }
    //     }


    // }, [form.housingType, enumCustomer]);

    useEffect(() => {

        if (form.laborType) {
            if (form.laborType === "ASA") {
                setSalaried(true);
                setIndependent(false);
                setOtherLaborType(false);

            }
            else if (form.laborType === "FRL") {
                setIndependent(true);
                setSalaried(false);
                setOtherLaborType(false);

                // handleChangeSelect({ target: { name: "laborContract", value: "" } })
            }
            else {
                setOtherLaborType(true);
                setIndependent(false);
                setSalaried(false);

                //handleChangeTypeahead([], "laborContract");
                handleChangeSelect({ target: { name: "laborContract", value: "" } })
                // handleChangeSelect({ target: { name: "laborActivity", value: "" } })
                //handleChangeTypeahead([], "laborActivity");
            }
        }
        else {
            setSalaried(true);
            setIndependent(false);
            setOtherLaborType(false);
        }


    }, [form.laborType]);

    useEffect(() => {

        //handleChangeTypeahead([], "stateCountry")

        if (form.residenceCountry) {

            if (form.residenceCountry !== "CO") {

                handleChangeSelect({ target: { name: "stateCountry", value: "" } })
                handleChangeSelect({ target: { name: "residenceCity", value: "" } })

            }


            let temp = stateCountriesTemp.filter(item => item.IdCountry === form.residenceCountry);


            if (temp)
                setStateCountries(temp);
            else
                setStateCountries([]);
        }
        else {

            setStateCountries([]);
            handleChangeSelect({ target: { name: "stateCountry", value: "" } })
        }



    }, [form.residenceCountry, stateCountriesTemp]);


    useEffect(() => {


        if (form.stateCountry && stateCountries.length > 0) {


            let mt = stateCountries.filter(sc => sc.value == form.stateCountry)[0].Municipalities



            setMunicipalities(mt)

            if (form.residenceCity && !mt.find(el => el.value === form.residenceCity)) {
                //handleChangeTypeahead([], "residenceCity")

                handleChangeSelect({ target: { name: "residenceCity", value: "" } })
            }

        }
        else {

            setMunicipalities([]);
            //handleChangeSelect({ target: { name: "residenceCity", value: "" } })
        }



    }, [form.stateCountry, stateCountries]);


    // useEffect(() => {

    //     handleChange({ target: { name: "personalReferences", value: personalReferences } })


    // }, [personalReferences]);


    const submitForm = (form, error) => {

        form.income = String(form.income).replaceAll(",", "").replaceAll(".", "").replaceAll("$", "")
        form.expenses = String(form.expenses).replaceAll(",", "").replaceAll(".", "").replaceAll("$", "")

        // form.personalReferences.forEach(p => {
        //     p.phone = p.phone.replaceAll(" ", "");
        // });

        if (!error) {
            setSending(true);


            let body =
            {
                cusType: "REG",
                // cusDocumentType: request.cusDocumentType,
                // cusDocument: request.cusDocument,

                // cusFirstName: request.cusFirstName,
                // cusSecondName: request.cusSecondName,
                // cusFirstSurname: request.cusFirstSurname,
                // cusSecondSurname: request.cusSecondSurname,

                // cusGender: request.cusGender,
                // cusBirthDate: request.cusBirthDate,
                // cusNationality: request.cusNationality,

                // cusPhoneCountry: request.cusPhoneCountry,

                cusAddress: form.address,
                cusLaborCompany: form.company,
                cusEconomicLevel: form.economicLevel,
                cusMaritalStatus: form.maritalStatus,
                cusEducationLevel: form.educationalLevel,
                cusHousingType: form.housingType,
                // cusHousingAntiquity: form.housingAntiquity,

                // cusHouseValue: form.housingType === "OWN" ? form.houseValue : null,
                // cusHouseRent: form.housingType === "REN" ? form.houseValue : null,

                cusLaborType: form.laborType,
                cusLaborContract: form.laborContract,
                // cusLaborActivity: form.laborActivity,
                cusLaborSalary: form.income,
                cusAddressCountry: form.residenceCountry,
                cusAddressCity: form.residenceCity ? String(form.residenceCity) : "",
                cusAddressState: form.stateCountry ? String(form.stateCountry) : "",
                //cusAddressTown: form.neighborhood,
                cusLaborAntiquity: form.laborAntiquity,
                cusNumDependents: form.dependents,
                // cusAssetsValues: form.assets,
                // cusLaborCompany: form.company,
                cusTotalExpenses: form.expenses,

                //cusReferences: form.personalReferences.map(el => { return { cusTypeReference: el.typeReference, cusReferenceFullName: el.name, cusReferencePhoneCountry: el.phoneCode[0].value, cusReferencePhone: el.phone } }),
                cusConditionsAcceptance: true
            }


            newCustomer(body).then(res => {

                setSending(false);

                if (res.status === 201) {
                    setStorage("request", {
                        ...request,
                        cusAddress: form.address,
                        cusEconomicLevel: form.economicLevel,

                        cusMaritalStatus: form.maritalStatus,

                        cusEducationLevel: form.educationalLevel,

                        cusHousingType: form.housingType,

                        // cusHousingAntiquity: form.housingAntiquity,

                        // cusHouseValue: form.houseValue,

                        cusLaborType: form.laborType,

                        cusLaborContract: form.laborContract,

                        //cusLaborActivity: form.laborActivity,

                        cusLaborSalary: form.income,
                        cusAddressCountry: form.residenceCountry,

                        cusAddressCity: form.residenceCity ? String(form.residenceCity) : "",
                        cusAddressState: form.stateCountry ? String(form.stateCountry) : "",

                        //cusAddressCityTemp: form.residenceCity,
                        //cusAddressStateTemp: form.stateCountry,

                        //cusAddressTown: form.neighborhood,

                        cusDependents: form.dependents,

                        // cusAssests: form.assets,

                        cusCompany: form.company,
                        cusExpenses: form.expenses,
                        cusLaborAntiquity: form.laborAntiquity,



                        // cusReferences: form.personalReferences.map(el => { return { cusTypeReference: el.typeReference, cusReferenceFullName: el.name, cusReferencePhoneCountry: el.phoneCode[0].value, cusReferencePhone: el.phone } }),
                        // cusReferencesTemp: form.personalReferences,
                        state: request.comingFromDashboard ? 9 : 14
                    });

                    if (request.comingFromDashboard) {
                        navigate("/Dashboard")
                    }
                    else {
                        navigate("/IdentityValidation");
                    }

                } else {
                    MySwal.fire({
                        html: res.data.message,
                        icon: 'error'
                    })
                }


            });






        }
        else {
            MySwal.fire({
                html: "Completa todos los campos",
                icon: 'warning'
            })
        }


    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <table id="step-five-table">
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", color: "black", fontSize: "13px", padding: "0px" }} colSpan={6}>
                                {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}

                            </td>

                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", color: "gray", fontSize: "13px" }} colSpan={6}>{request.cusDocumentTypeTemp}   {request.cusDocument}</td>

                        </tr>

                        <tr>
                            <td style={{ textAlign: "center", color: "gray", paddingTop: "15px" }} colSpan={6}>
                                <h3><b style={{ color: "black" }}>3</b>/4</h3>
                            </td>
                        </tr>
                        <tr>
                            <td>


                                <CustomSelect ref={educationalLevelRef} name="educationalLevel" value={form.educationalLevel} options={educationalLevels} text="Nivel educativo" error={errors.educationalLevel} handleChangeSelect={handleChangeSelect} />

                            </td>
                            <td>

                                <CustomSelect ref={maritalStatusRef} name="maritalStatus" value={form.maritalStatus} options={maritalStatuses} text="Estado civil" error={errors.maritalStatus} handleChangeSelect={handleChangeSelect} />

                            </td>
                        </tr>
                        <tr>
                            <td>

                                <CustomSelect ref={housingTypeRef} name="housingType" value={form.housingType} options={housingTypes} text="Tipo de vivienda" error={errors.housingType} handleChangeSelect={handleChangeSelect} />

                            </td>
                            <td>
                                <CustomSelect ref={economicLevelRef} name="economicLevel" value={form.economicLevel} options={economicLevels} text="Estrato" error={errors.economicLevel} handleChangeSelect={handleChangeSelect} />

                                {/* <CustomSelect ref={housingAntiquityRef} name="housingAntiquity" value={form.housingAntiquity} options={housingAntiquities} text="Años en vivienda" error={errors.housingAntiquity} handleChangeSelect={handleChangeSelect} /> */}

                            </td>
                        </tr>

                        {/* <tr>
                            <td>

                                <CustomSelect ref={houseValueRef} name="houseValue" disabled={form.housingType && (form.housingType === "OTH" || form.housingType === "FAM")} value={form.houseValue} options={houseValues} text={labelHouseValue} error={errors.houseValue} handleChangeSelect={handleChangeSelect} />

                            </td>
                            <td>

                   
                            </td>
                        </tr> */}

                        <tr>
                            <td>

                                <CustomSelect ref={dependentsRef} name="dependents" value={form.dependents} options={dependents} text="Personas a cargo" error={errors.dependents} handleChangeSelect={handleChangeSelect} />

                            </td>
                            {/* <td>

                                <CustomSelect ref={assetsRef} name="assets" value={form.assets} options={assets} text="Activos" error={errors.assets} handleChangeSelect={handleChangeSelect} />

                            </td> */}

                            <td>

                                <CustomSelect name="residenceCountry" value={form.residenceCountry} options={residenceCountries} text="País de residencia" error={errors.residenceCountry} handleChangeSelect={handleChangeSelect} />

                            </td>
                        </tr>

                        <tr>

                            <td>

                                <CustomSelect ref={stateCountryRef} name="stateCountry" disabled={stateCountries.length === 0} value={form.stateCountry} options={stateCountries} text="Departamento de residencia" error={errors.stateCountry} handleChangeSelect={handleChangeSelect} />

                            </td>

                            <td>

                                <CustomSelect ref={residenceCityRef} name="residenceCity" disabled={municipalities.length === 0} value={form.residenceCity} options={municipalities} text="Ciudad" error={errors.residenceCity} handleChangeSelect={handleChangeSelect} />

                            </td>
                        </tr>


                        <tr>
                            <td colSpan={2}>
                                <div className="form-floating">
                                    <input ref={addressRef} type="text" name="address" value={form.address} onChange={handleChange} className={`form-control ${errors.address && "is-invalid"}`} placeholder="Dirección" autoComplete="off" />
                                    <label htmlFor="address">Dirección</label>
                                </div>
                            </td>
                        </tr>

                       

                        <tr>
                            <td colSpan={2}>


                                <CustomSelect ref={laborTypeRef} name="laborType" value={form.laborType} options={laborTypes} text="Ocupación" error={errors.laborType} handleChangeSelect={handleChangeSelect} />

                            </td>


                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <div className="form-floating">
                                    <input ref={companyRef} type="text" name="company" value={form.company} onChange={handleChange} className={`form-control ${errors.company && "is-invalid"}`} placeholder="Empresa/fuente de ingresos" autoComplete="off" />
                                    <label htmlFor="company">Empresa / fuente de ingresos</label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>


                                <CustomSelect ref={laborContractRef} name="laborContract" disabled={otherLaborType} value={form.laborContract} options={laborContracts} text="Tipo de contrato" error={errors.laborContract} handleChangeSelect={handleChangeSelect} />

                            </td>
                            <td>


                                <CustomSelect ref={laborAntiquityRef} name="laborAntiquity" value={form.laborAntiquity} options={laborAntiquities} text="Antiguedad ocupación" error={errors.laborAntiquity} handleChangeSelect={handleChangeSelect} />

                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div className="form-floating">

                                    <MaskedInput ref={incomeRef} mask={currencyMask} name="income" value={form.income} onChange={handleChange} placeholder="Ingresos" className={`form-control ${errors.income && "is-invalid"}`} autoComplete="off"></MaskedInput >
                                    <label htmlFor="income">Ingresos</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-floating">

                                    <MaskedInput ref={expensesRef} mask={currencyMask} name="expenses" value={form.expenses} onChange={handleChange} placeholder="Egresos" className={`form-control ${errors.expenses && "is-invalid"}`} autoComplete="off"></MaskedInput >
                                    <label htmlFor="expenses">Egresos</label>
                                </div>
                            </td>

                        </tr>
                        {/* <tr>
                            <td colSpan={2}>


                                <CustomSelect ref={laborActivityRef} name="laborActivity" disabled={otherLaborType} value={form.laborActivity} options={laborActivities} text="Actividad economica" error={errors.laborActivity} handleChangeSelect={handleChangeSelect} />

                            </td>
                        </tr> */}
                        {/* <tr>
                            <td style={{ textAlign: "center", color: "gray" }} colSpan={2}>
                                <hr className="hr-text" data-content="Referencias personales"></hr>
                            </td>
                        </tr>

                        {

                            personalReferences.map((r, index) => {
                                return (
                                    <React.Fragment key={r.key}>
                                        <tr>
                                            <td style={{ textAlign: "center", color: "gray" }} colSpan={2}>Referencia {index + 1}</td>
                                        </tr>
                                        <tr >

                                            <td colSpan={2}>
                                                

                                                <CustomSelect name="typeReference" value={r.typeReference} options={typeReferences} text="Relación" error={errors.personalReferences && errors.personalReferences.find(el => el.key === r.key) && errors.personalReferences.find(el => el.key === r.key).typeReference}
                                                    handleChangeSelect={e => {
                                                        let prt = personalReferences.map(rt => { if (rt.key === r.key) { return { ...rt, typeReference: e.target.value } } else { return rt } })
                                                        setPersonalReferences(prt);
                                                    }} />

                                            </td>

                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="form-floating">
                                                    <input type="text" name="name" value={r.name} onChange={
                                                        (e) => {
                                                            const { name, value } = e.target;
                                                            let prt = personalReferences.map(rt => { if (rt.key === r.key) { return { ...rt, [name]: value } } else { return rt } })
                                                            setPersonalReferences(prt);
                                                        }
                                                    }
                                                        className={`form-control ${errors.personalReferences && errors.personalReferences.find(el => el.key === r.key) && errors.personalReferences.find(el => el.key === r.key).name && "is-invalid"}`} placeholder="Nombres y apellidos" autoComplete="off" />
                                                    <label htmlFor="relation">Nombres y apellidos</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <IntlTelInput
                                                    containerClassName="intl-tel-input"
                                                    inputClassName={`form-control ${errors.personalReferences && errors.personalReferences.find(el => el.key === r.key) && errors.personalReferences.find(el => el.key === r.key).phone && "is-invalid"}`}
                                                    placeholder="Teléfono"
                                                    preferredCountries={['co', 'us']}
                                                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                        let prt = personalReferences.map(rt => { if (rt.key === r.key) { return { ...rt, phone: value.replaceAll(" ", "") } } else { return rt } })
                                                        setPersonalReferences(prt);
                                                    }}
                                                    onSelectFlag={(num, country) => {
                                                        let phoneCode = phoneCodes.find(el => el.name === Number(country.dialCode));
                                                        let prt = personalReferences.map(rt => { if (rt.key === r.key) { return { ...rt, phoneCode: [phoneCode], phoneCodeTemp: country.iso2 } } else { return rt } })
                                                        setPersonalReferences(prt);
                                                    }}
                                                    name="phone"
                                                    value={r.phone}
                                                    defaultCountry={r.phoneCodeTemp}
                                                />

                                            </td>

                                        </tr>
                                        {index !== 0 && (<tr>

                                            <td style={{ textAlign: "right" }} colSpan={2}>
                                                <i className="bi bi-trash" type="button" onClick={() => {
                                                    let prt = personalReferences.filter(pr => pr.key !== r.key)
                                                    setPersonalReferences(prt)
                                                }}></i>
                                            </td>
                                        </tr>)}

                                    </React.Fragment>



                                )
                            })

                        }
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>
                                <button style={{ backgroundColor: "#ff9e25", borderColor: "#ff9e25" }} type="button" className="btn btn-mp-back" onClick={() => { if (personalReferences.length < 5) setPersonalReferences([...personalReferences, { key: Date.now(), typeReference: "", name: "", phoneCode: [{ value: "CO", name: "57", control: "phoneCode" }], phoneCodeTemp: "co", phone: "" }]) }}>Añadir otra referencia</button>
                            </td>
                        </tr> */}

                        {request.comingFromDashboard && (<>
                            <tr>
                                <td colSpan={2} style={{ textAlign: "center" }}>

                                    <div className="form-check" style={{ marginLeft: "10px" }}>
                                        <input className="form-check-input" type="checkbox" checked={form.dataPrivacyCheck} id="checkPrivacidad" name="dataPrivacyCheck" onChange={handleChecked} style={{ float: "initial", fontSize: "25px" }} />
                                        <label className="form-check-label" style={{ marginLeft: "10px", textAlign: "left" }}>
                                            Acepto la <u style={{ color: "blue", cursor: "pointer" }} onClick={handleShow1}>política de privacidad y tratamiento de datos personales</u>
                                            {/* Acepto la <a target="_blank" rel="noopener noreferrer" href="https://www.meddipay.com.co/privacy?section=policy">política de privacidad y tratamiento de datos personales</a> */}

                                        </label>
                                    </div>


                                </td>
                            </tr>

                            <tr>
                                <td colSpan={2} style={{ textAlign: "center" }}>
                                    <div className="form-check" style={{ marginLeft: "10px" }}>
                                        <input className="form-check-input" type="checkbox" checked={form.dataTreatmentCheck} id="checkTratamiento" name="dataTreatmentCheck" onChange={handleChecked} style={{ float: "initial", fontSize: "25px" }} />
                                        <label className="form-check-label" style={{ marginLeft: "10px", textAlign: "left" }}>
                                            Acepto el <u style={{ color: "blue", cursor: "pointer" }} onClick={handleShow}>Aviso de privacidad</u> y a ser contactado por los siguientes canales de cobranza:<br /> SMS, Correo electrónico, Llamada telefónica, Mensaje por aplicaciones
                                            {/* Acepto el <a target="_blank" rel="noopener noreferrer" href="https://www.meddipay.com.co/privacy?section=notice">Aviso de privacidad</a> y a ser contactado por los siguientes canales de cobranza:<br /> SMS, Correo electrónico, Llamada telefónica, Mensaje por aplicaciones */}
                                        </label>
                                    </div>

                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "center", color: "red" }} colSpan={2}>
                                    {(errors.dataPrivacyCheck || errors.dataTreatmentCheck) && (<p>Debe aceptar las políticas</p>)}
                                </td>
                            </tr>
                        </>)}
                        <tr>

                            <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>


                                <button type="button" className="btn btn-mp-back" disabled={sending} style={{ marginRight: "20px" }} onClick={() => {

                                    if (request.comingFromDashboard) {

                                        setStorage("request", {
                                            ...request,
                                            state: 9,
                                        });

                                        navigate("/Dashboard");


                                    } else {
                                        setStorage("request", {
                                            ...request,
                                            state: 0,
                                            updateCustomer: true
                                        });

                                        navigate("/");
                                    }


                                }}>Regresar</button>


                                <input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form >

            <Modal show={show} onHide={handleClose} dialogClassName="modal-aut">
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body>
                    <div style={{ margin: "0 auto", width: "100%", textAlign: "justify", overflowY: "scroll", height: "500px", padding: "30px" }}>
                        <Privacy />
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataTreatmentCheck", checked: true } }); handleClose() }}>He leido y acepto</Button>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataTreatmentCheck", checked: false } }); handleClose() }}>No acepto</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show1} onHide={handleClose1} dialogClassName="modal-aut" centered>
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body style={{ padding: "0" }}>
                    <div style={{ margin: "0 auto", width: "100%", textAlign: "justify", overflowY: "scroll", height: "500px", padding: "30px" }}>

                        <PersonalData />

                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: true } }); handleClose1() }}>He leido y acepto</Button>
                    <Button variant="secondary" onClick={() => { handleChecked({ target: { name: "dataPrivacyCheck", checked: false } }); handleClose1() }}>No acepto</Button>
                </Modal.Footer>
            </Modal >

        </>

    );
}

export default RequestStepFive;