import '../StyleSheets/CreditRequestHome.css';
import Logo from '../Images/logo_bluegray.svg';
import { Link, Outlet } from "react-router-dom";
import { useCreditRequest } from '../Context/useCreditRequest';
import { useState } from 'react';
import { useCreditRequestAuth } from '../Context/useCreditRequestAuth';


function CreditRequestHome() {

  const { logoSize } = useCreditRequest();

  const { cookies, setCookies } = useCreditRequestAuth();
  const [visibilityCookies, setVisibilityCookies] = useState(!cookies.acceptCookies ? "initial" : cookies.acceptCookies === "1" ? "none" : "initial");

  const styles = {
    logo: logoSize
  }

  const acceptCookies = () => {
    setVisibilityCookies("none");
    setCookies("acceptCookies", "1", { expires: new Date("2100-01-01"), secure: true, sameSite: "None" })
  }

  return (

    <>
      <div className="logo-container">
        <Link to="/">
          <img className='logo' style={styles.logo} src={Logo} alt="logo" />
        </Link>

      </div>

      <Outlet />

      <div className="cookie-consent-banner" style={{ display: visibilityCookies }}>
        <div className="cookie-consent-banner__inner">
          <div className="cookie-consent-banner__copy">
            <div className="cookie-consent-banner__header" style={{ padding: "13px" }}>Aviso de aceptación de cookies</div>
            <div className="cookie-consent-banner__description" style={{ padding: "13px" }}>Su privacidad es importante para nosotros. Este sitio web usa cookies en su dispositivo, las cuales son utilizadas para almacenar la información de su interacción con la página web, para fines analíticos y de optimización de la web. Al dar clic en aceptar, usted acepta nuestra política de cookies. Para conocer más acerca de las cookies, <u style={{ color: "blue", cursor: "pointer" }} onClick={() => { window.open("https://www.meddipay.com.co/cookies", "_blank", "noreferrer") }}>consulta nuestra política de cookies</u>
            </div>
          </div>

          <div className="cookie-consent-banner__actions" style={{ padding: "13px" }}>
            <a className="cookie-consent-banner__cta" onClick={acceptCookies} style={{ cursor: "pointer" }}>Aceptar
            </a>


          </div>
        </div>
      </div>

    </>



  );

}

export default CreditRequestHome;
