import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import WompiService from '../Services/WompiService';
import { useCreditRequest } from '../Context/useCreditRequest';
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif';
import imgBlueCheck from '../Images/blue_check.svg';
import imgDelete from '../Images/delete.svg';
import imgPayment from '../Images/payment.svg';

const PaymentResult = () => {

    const navigate = useNavigate();
    const { GetTransaction } = WompiService();
    const [transactionResult, setTransactionResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const moneyLocale = Intl.NumberFormat('es-CO');
    const [reference, setReference] = useState(null);
    const { setLogoSize } = useCreditRequest();

    useEffect(() => {
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" })
        const queryParameters = new URLSearchParams(window.location.search)
        const id = queryParameters.get("id")

        setLoading(true);

        GetTransaction(id).then(res => {
            setLoading(false);

            if (res.status === 200) {
                setTransactionResult(res.data.data)
                setReference(res.data.data.reference.split("-")[0])

                // if (res.data.data.status === "APPROVED") {
                //     document.title = "Pago exitoso";
                // } else {
                //     document.title = "Pago fallido";
                // }
            }
            else {
                navigate("/Dashboard");
            }

        })


    }, []);

    return (<>
        <Container style={{ marginTop: "0px", marginBottom: "30px" }}>
            <Row style={{ display: transactionResult && transactionResult.status === "APPROVED" ? "flex" : "none" }}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                    <img style={{ width: "70px" }}
                        src={imgBlueCheck}
                        alt="payment" />
                    <div style={{ margin: "20px auto" }} className="payment-text">
                        <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "#00A9EB" }}>exitoso</span></h1>
                        <p style={{ textAlign: "left", marginTop: "20px" }} >
                            Gracias por tu compromiso, continúa disfrutando de todas las ventajas que Meddipay te ofrece.
                            <br />
                            A continuación encontraras los detalles de la transacción que te también te servirá como comprobante de pago.
                        </p>
                        <p style={{ fontWeight: "bold" }}>ID Pago<br />
                            <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.id}</span></p>
                        <p style={{ fontWeight: "bold" }}>Valor<br />
                            <span style={{ color: "#00a9eb", fontWeight: "bold" }}>${moneyLocale.format(transactionResult?.amount_in_cents / 100)}</span></p>
                        <p style={{ fontWeight: "bold" }}>Fecha<br />
                            <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.finalized_at}</span></p>
                        <p style={{ fontWeight: "bold" }}>Referencia Interna<br />
                            <span style={{ color: "#00a9eb", fontWeight: "bold" }}>{transactionResult?.reference}</span></p>

                        <br />
                        <input type="submit" value="Continuar" disabled={loading} onClick={() => navigate("/Dashboard")} className="btn btn-mp" />
                    </div>

                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                    <img className="img-fluid"
                        src={imgPayment}
                        alt="payment" />
                </Col>
            </Row>
            <Row style={{ display: transactionResult && transactionResult.status !== "APPROVED" ? "flex" : "none" }}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                    <img style={{ width: "70px" }}
                        src={imgDelete}
                        alt="payment" />
                    <div style={{ margin: "20px auto" }} className="payment-text">
                        <h1 style={{ fontWeight: "bold" }}>Pago <span style={{ color: "red" }}>fallido</span></h1>
                        <p style={{ textAlign: "left", marginTop: "20px" }} >
                            Ha ocurrido un error a la hora de procesar el pago. A continuacion encontraras mas detalles. </p>
                        <p style={{ fontWeight: "bold" }}>ID Pago<br />
                            <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.id}</span></p>
                        <p style={{ fontWeight: "bold" }}>Mensaje<br />
                            <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.status_message}</span></p>
                        <p style={{ fontWeight: "bold" }}>Fecha<br />
                            <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.finalized_at}</span></p>
                        <p style={{ fontWeight: "bold" }}>Referencia Interna<br />
                            <span style={{ color: "red", fontWeight: "bold" }}>{transactionResult?.reference}</span></p>

                            <br />
                        <input type="submit" value="Continuar" disabled={loading} onClick={() => navigate("/Dashboard")} className="btn btn-mp" />
                  
                    </div>

                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "center" }}>
                    <img className="img-fluid"
                        src={imgPayment}
                        alt="payment" />
                </Col>
            </Row>
        </Container>

        {loading &&
            (
                <div className="my-loader">
                    <div className="my-loader-container">
                        <img src={imgLoader} alt="loader"></img>

                    </div>
                </div>
            )}

    </>)
}


export default PaymentResult;