import React, { useEffect, useState } from "react";
import { useCreditRequest } from '../Context/useCreditRequest';
import '../StyleSheets/DigitalConfirmation.css'
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { Link, useNavigate } from 'react-router-dom';
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

const DigitalConfirmation = () => {

    const { setLogoSize } = useCreditRequest();
    const [sending, setSending] = useState(false);
    const navigate = useNavigate();
    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request")

    useEffect(() => {
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" });
    }, []);

    const smsOTP = () => {
        setSending(true);
        setStorage("request", {
            ...request,
            OTP: 2,
            state: 3
        });
        navigate("/DigitalConfirmationLink");
    }

    const emailOTP = () => {
        setSending(true);
        setStorage("request", {
            ...request,
            OTP: 1,
            state: 3
        });
        navigate("/DigitalConfirmationLink");
    }


    const waOTP = () => {
        setSending(true);
        setStorage("request", {
            ...request,
            OTP: 3,
            state: 3
        });
        navigate("/DigitalConfirmationLink");
    }

    return (
        <>


            <table id="dc-table">
                <tbody>

                    <tr>
                        <td style={{ textAlign: "center", color: "black", fontSize: "13px", padding: "0px" }} colSpan={2}>
                            {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}

                        </td>

                    </tr>
                    <tr>
                        <td style={{ textAlign: "center", color: "gray", fontSize: "13px", padding: "0px" }} colSpan={2}>

                            {request.cusDocumentTypeTemp}   {request.cusDocument}
                        </td>

                    </tr>
                    <tr>
                        <td style={{ textAlign: "center", paddingTop: "15px" }} colSpan={2}>
                            <h2>Verificación electrónica</h2>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: "center", color: "gray", paddingTop: "15px" }} colSpan={2}>
                            <h3><b style={{ color: "black" }}>2</b>/4</h3>
                        </td>
                    </tr>


                    {(request.origin  === 1 || (request.origin === 2 && request.idMethodWA)) && <tr>
                        <td>
                            <div className="divBox" onClick={waOTP}>

                                <div className="container-fluid">
                                    <div className="row" >

                                        <div className="col-md-3">
                                            <i className="bi bi-whatsapp" style={{ fontSize: "35px" }}></i>
                                        </div>
                                        <div className="col-md-9">
                                            <b>Mediante WhatsApp</b><br />
                                            {request.phoneCodeTemp} {request.cusPhone}
                                        </div>



                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>}

                    <tr>
                        <td>
                            <div className="divBox" onClick={emailOTP}>

                                <div className="container-fluid">
                                    <div className="row" >

                                        <div className="col-md-3">
                                            <i className="bi bi-envelope-at" style={{ fontSize: "35px" }}></i>
                                        </div>
                                        <div className="col-md-9">
                                            <b>Mediante correo electrónico</b><br />
                                            {request.cusEmail}
                                        </div>



                                    </div>
                                </div>



                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="divBox" onClick={smsOTP}>

                                <div className="container-fluid">
                                    <div className="row" >

                                        <div className="col-md-3">
                                            <i className="bi bi-chat-dots" style={{ fontSize: "35px" }}></i>
                                        </div>
                                        <div className="col-md-9">
                                            <b>Mediante mensaje de texto</b><br />
                                            {request.phoneCodeTemp} {request.cusPhone}
                                        </div>



                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>



                    <tr>

                        <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>
                            <Link to="/Enrollment" onClick={() => {

                                if (request.origin === 1) {
                                    setStorage("request", {
                                        ...request, state: 1
                                    })
                                }
                                else {
                                    setStorage("request", {
                                        ...request, state: 0
                                    })
                                }

                            }}>
                                <button type="button" disabled={sending} className="btn btn-mp-back">Regresar</button>
                            </Link>

                            {/* <button type="button" disabled={sending} className="btn btn-mp" onClick={submit}  >Enviar</button> */}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {sending &&
                                (
                                    <div className="my-loader">
                                        <div className="my-loader-container">
                                            <img src={imgLoader} alt="loader"></img>

                                        </div>
                                    </div>
                                )}
                        </td>
                    </tr>
                </tbody>

            </table>

        </>

    );
}

export default DigitalConfirmation;