import { SoraLogin } from "../Config";
import axios from "axios";

export const getChangePasswordRequest = async (parameter) => {
    let respose = await axios.get(SoraLogin('User/ChangePasswordRequest'),
        {
            params: parameter
        })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return {
                    result: '400',
                    responseText: 'Error de origen tipo Bad Request',
                    data: ''
                };
            } else {
                return {
                    result: '400',
                    responseText: 'Error en código front: ' + error.message,
                    data: ''
                };
            }
        });
    return respose;
};

export const getVerifyTokenChangePassword = async (parameter) => {
    let respose = await axios.get(SoraLogin('User/VerifyTokenChangePassword'),
        {
            params: parameter
        })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return {
                    result: '400',
                    responseText: 'Error de origen tipo Bad Request',
                    data: ''
                };
            } else {
                return {
                    result: '400',
                    responseText: 'Error en código front: ' + error.message,
                    data: ''
                };
            }
        });
    return respose;
};

export const ResetPasswordToken = async (parameter) => {
    let respose = await axios.get(SoraLogin('User/ResetPasswordToken'),
        {
            params: parameter
        })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return {
                    result: '400',
                    responseText: 'Error de origen tipo Bad Request',
                    data: ''
                };
            } else {
                return {
                    result: '400',
                    responseText: 'Error en código front: ' + error.message,
                    data: ''
                };
            }
        });
    return respose;
};

export const GetToken = async (body) => {
    let respose = await axios.post(SoraLogin('User/Login'),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return {
                    result: '400',
                    responseText: 'Error de origen tipo Bad Request',
                    data: ''
                };
            } else {
                return {
                    result: '400',
                    responseText: 'Error en código front: ' + error.message,
                    data: ''
                };
            }
        });
    return respose;
};


export const IsKnownCustomer = async (DocumentType, Document) => {

    let respose = await axios.get(SoraLogin("Customers/IsKnownCustomer?DocumentType=" + DocumentType + "&Document=" + Document),
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response; // => the response payload 
            }
            return { status: 500, data: { message: "ERROR" } }
        })

    return respose;

};




export const GenerateJWTSessionCustomer = async (body) => {

    let respose = await axios.post(SoraLogin("Customers/GenerateJWTSessionCustomer"),
        body,
        {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(( data ) => {
            return data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data; // => the response payload 
            }
            return { error: 1 }
        })

    return respose;

};

