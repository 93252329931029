import React, { useEffect, useState } from 'react';
import '../StyleSheets/RequestStepSix.css'
import { useNavigate } from 'react-router';
import { useCreditRequest } from '../Context/useCreditRequest';
import { createNumberMask } from 'text-mask-addons';
import MaskedInput from 'react-text-mask';
import useForm from '../Hooks/useForm';
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { Link } from 'react-router-dom';
import { Typeahead, Hint } from 'react-bootstrap-typeahead';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import TarsServices from "../Services/TarsServices";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useCreditRequestAuth } from '../Context/useCreditRequestAuth';
import imgDoctor from '../Images/doctor.svg';
import imgAddress from '../Images/address.svg';
import imgReference from '../Images/reference.svg';
import imgtreatments from '../Images/treatments.svg';
import imgtreatment from '../Images/treatment.svg';
import imgImpfin from '../Images/impfin.svg';
import imgDay from '../Images/term.svg';
import imgTerm from '../Images/term2.svg';
import imgPatients from '../Images/patients.png';
import Badge from 'react-bootstrap/Badge';
import CustomSelect from './CustomSelect';
import Skeleton from 'react-loading-skeleton';



const RequestStepSix = () => {
    const { getStorage, setStorage, logout, clearRequest } = useCreditRequestAuth();
    let request = getStorage("request")
    const navigate = useNavigate();
    const initialForm = { treatmentCost: request.invitation.AmountTreatment, loanAmount: request.invitation.AmountFinance, term: "", payDay: "" };

    const { setLogoSize } = useCreditRequest();

    const [terms, setTerms] = useState([]);
    const [payDays, setPayDays] = useState([]);
    const [doctor, setDoctor] = useState();
    const [isSimulated, setIsSimulated] = useState(false);
    const [applicationData, setApplicationData] = useState(null);
    const [tableAmortization, setTableAmortization] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const termRef = React.useRef();
    const payDayRef = React.useRef();
    const treatmentsRef = React.useRef();
    const { newCustomer, SimulateApplication, createApplication, GetPosById } = TarsServices();
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [show, setShow] = useState(false);
    const [showModalPD, setShowModalPD] = useState(false);
    const [cusPayDayData, setCusPayDayData] = useState(null);
    const [tempPayDay, setTempPayDay] = useState(null);
    const [allData, setAllData] = useState(null);
    const [editPD, setEditPD] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const MySwal = withReactContent(Swal);

    const validateForm = (form) => {

        form.loanAmount = String(form.loanAmount).trim().replaceAll(",", "").replaceAll(".", "").replaceAll("$", "")
        form.treatmentCost = String(form.treatmentCost).trim().replaceAll(",", "").replaceAll(".", "").replaceAll("$", "")

        let errors = {};

        if (!form.treatmentCost) { errors.treatmentCost = "El campo Costo del tratamiento es requerido" }
        if (!form.loanAmount) { errors.loanAmount = "El campo Costo del tratamiento es requerido" }

        if (form.loanAmount && (Number(form.loanAmount) > doctor.posMaxLoanAmt || Number(form.loanAmount) < doctor.posMinLoanAmt || Number(form.loanAmount) > request.cusPurchaseAmt)) {
            errors.loanAmount = `El importe a financiar debe estar entre ${doctor.posMinLoanAmt} y ${doctor.posMaxLoanAmt}`
        }

        if (form.loanAmount && (Number(form.loanAmount) > request.cusPurchaseAmt)) {
            errors.loanAmount = `El importe a financiar no puede ser mayor al costo del tratamiento`
        }

        if (!form.term) { errors.term = "El campo Plazo es requerido" }
        //if (!form.payDay) { errors.payDay = "El campo Dia de pago es requerido" }

        return errors;

    };

    const { form, errors, setErrors, handleChange, handleSubmit, resetForm, handleChangeTypeahead, handleChangeSelect } = useForm(initialForm, validateForm);

    const [sending, setSending] = useState(false);
    let moneyLocale = Intl.NumberFormat('es-CO');


    useEffect(() => {
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" })
        window.scrollTo(0, 0)


        GetPosData();

        //setTreatments(request.invitation.Treatments.map(el => { return { value: el, name: el } }))
    }, []);

    const GetPosData = () => {

        setShowSkeleton(true)

        GetPosById(request.invitation.IdPos, request.invitation.IdInvitation).then(res => {
            setShowSkeleton(false)

            if (res.status === 200) {
                let data = JSON.parse(res.data.data)

                setStorage("request", {
                    ...request,
                    FullName: data.FullName
                });

                setAllData(data)
                
                setDoctor(data.Terms);
                setTempPayDay(data.CusPayDayData?.LabelCusPayDay)

                setPayDays(data.PayDays.map(el => {
                    return {
                        value: el.IdPayDay, name: el.PayDayKeyTranslated, control: "payDay"
                    }
                }))

                if (!data.CusPayDay) {
                    setShowModalPD(true)
                }
            }
            else {

                MySwal.fire({
                    html: res.data.message,
                    icon: 'error'
                })
            }
        });
    }

    useEffect(() => {
        handleChangeSelect({ target: { name: "term", value: "" } })
        handleChangeSelect({ target: { name: "payDay", value: "" } })
        setIsSimulated(false);
        setApplicationData(null);
        setTerms([]);
        // setPayDays([]);

        if (doctor) {

            if (doctor.length > 0) {
                let la = Number(String(form.loanAmount).trim().replaceAll(",", "").replaceAll(".", "").replaceAll("$", ""))

                if (la <= Number(request.invitation.AmountTreatment)) {

                    let termsTemp = doctor.filter(el => la >= Number(el.MinLoanAmount) && la <= Number(el.MaxLoanAmount))
                        .map(el => {
                            return el
                        });

                    // console.log(termsTemp)
                    setTerms(termsTemp);
                }
            }
            else {

                MySwal.fire({
                    html: "POS sin oferta comercial",
                    icon: 'error'
                })

                setStorage("request", {
                    ...request,
                    state: 9
                });


                navigate("/Dashboard");
            }




        }



    }, [doctor, form.loanAmount]);

    // useEffect(() => {
    //     // setIsSimulated(false);
    //     // setApplicationData(null);




    //     // if (form.term) {

    //     //     let tempDays = terms.find(t => t.value == form.term);

    //     //     if (tempDays) {
    //     //         setPayDays(tempDays.posTermsPayDays.map(el => {
    //     //             return {
    //     //                 value: el.IdPayDay, name: el.PayDayKeyTranslated, control: "payDay"
    //     //             }
    //     //         }));
    //     //     }
    //     //     else {
    //     //         handleChangeSelect({ target: { name: "payDay", value: "" } })
    //     //         setPayDays([]);
    //     //     }

    //     // }

    // }, [form.term]);

    // useEffect(() => {
    //     setIsSimulated(false);
    //     setApplicationData(null);
    // }, [form.payDay]);

    useEffect(() => {

        if (allData && allData.CusPayDayData && allData.CusPayDayData.IdCusPayDay)
            handleChangeSelect({ target: { name: "payDay", value: allData.CusPayDayData.IdCusPayDay } })

    }, [allData]);

    const changePayDay = () => {

        if (allData.CusPayDayData && allData.CusPayDayData.AllowChangePayDay) {
            handleChangeSelect({ target: { name: "payDay", value: allData.CusPayDayData.IdCusPayDay } })
            setEditPD(true)
            setShowModalPD(true)
        }
        else if (allData.CusPayDayData && !allData.CusPayDayData.AllowChangePayDay) {
            MySwal.fire({
                html: allData.CusPayDayData.MessageChangePayDay,
                icon: 'warning'
            })
        }
    }

    const submitForm = (form, error) => {

        if (!applicationData) {

            MySwal.fire({
                html: "Seleccione un plazo",
                icon: 'warning'
            })

            return
        }

        if (!error) {
            setSending(true);

            let body = {
                appPOS: request.invitation.IdPos,
                appPurchaseAmt: String(form.treatmentCost).trim().replaceAll(",", "").replaceAll(".", "").replaceAll("$", ""),
                appApplicationAmount: String(form.loanAmount).trim().replaceAll(",", "").replaceAll(".", "").replaceAll("$", ""),
                appIdTerm: String(selectedTerm),
                //appPayDay: form.payDay,
                appProductSale: request.invitation.Treatments && request.invitation.Treatments.length > 0 ? request.invitation.Treatments.join(", ") : "",
                appIdInvitation: request.invitation.IdInvitation
            }


            createApplication(body).then(res => {

                let data = JSON.parse(res.data.data)

                if (res.status === 200) {

                    setStorage("request", {
                        ...request,
                        IdApplication: data.IdApplication,
                        Reference: data.Reference,
                        COFI: data.appContracts.find(c => c.TypeContract === "COFI").Id,
                        PYNO: data.appContracts.find(c => c.TypeContract === "PYNO").Id,
                        LabelTerm: applicationData.LabelTerm,
                        LabelRegularInstalment: applicationData.LabelRegularInstalment,
                        PayDay: tempPayDay,
                        FirstInstalmentDate: applicationData.FirstInstalmentDate,
                        state: 12
                    })

                    navigate("/eSign")

                } else {
                    setSending(false);
                    MySwal.fire({
                        html: res.data.message,
                        icon: 'error'
                    })
                }

            })



        }
    }

    const savePayDay = () => {
        setSending(true);


        if (!form.payDay) {

            setErrors({ payDay: !form.payDay ? "abc" : undefined })
            setSending(false);
            return;
        }


        let body = { cusPayDay: form.payDay }

        newCustomer(body).then(res => {

            setSending(false);

            if (res.status === 201 || res.status === 200) {


                setTempPayDay(payDays.find(p => p.value === form.payDay).name)

                setShowModalPD(false);

                setIsSimulated(false);

                setSelectedTerm(0)

                GetPosData();
            }
            else {
                MySwal.fire({
                    html: res.data.message,
                    icon: 'error'
                })
            }
        });

    }

    const handleCloseModalPD = (r, e) => {
        setShowModalPD(false)

    }

    // const simulate = () => {
    //     setIsSimulated(true);
    //     setSending(true);

    //     if (!form.loanAmount || !form.term) {

    //         setErrors({ term: !form.term ? "abc" : undefined, payDay: !form.payDay ? "abc" : undefined })
    //         setIsSimulated(false);
    //         setSending(false);

    //         // MySwal.fire({
    //         //     html: "Complete lo campos!",
    //         //     icon: 'info'
    //         // })
    //         return;
    //     }



    //     let body = {
    //         AppPOS: request.invitation.IdPos,
    //         AppPurchaseAmt: String(form.treatmentCost).trim().replaceAll(",", "").replaceAll(".", "").replaceAll("$", ""),
    //         AppApplicationAmount: String(form.loanAmount).trim().replaceAll(",", "").replaceAll(".", "").replaceAll("$", ""),
    //         AppTerm: String(form.term),
    //         AppPayDay: form.payDay,
    //         AppProductSale: request.invitation.Treatments && request.invitation.Treatments.length > 0 ? request.invitation.Treatments.join(", ") : ""
    //     }


    //     SimulateApplication(body).then(res => {
    //         setSending(false);


    //         if (res.status === 200) {
    //             let responseData = JSON.parse(res.data.data);


    //             setApplicationData(responseData.ApplicationData);
    //             setTableAmortization(responseData.TableAmortization);
    //         }
    //         else {
    //             MySwal.fire({
    //                 html: res.data.message,
    //                 icon: 'error'
    //             })
    //         }


    //     })
    // }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <table id="step-six-table">
                    <tbody>


                        <tr>
                            <td colSpan={2}>
                                <p style={{ lineHeight: "23px", fontSize: "23px" }}>
                                    <span style={{ fontWeight: "bold" }}>Invitación <span style={{ color: "#00A9EB" }}>#{request.invitation.IdInvitation}</span></span>  <br />
                                    <span style={{ fontSize: "12px" }}>
                                        <span style={{ color: "gray" }}>{request.invitation.LabelDateCreated}</span> | {request.invitation.LabelExpirationDate}
                                    </span>

                                </p>

                            </td>

                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgDoctor} className="invitation-icon" />Especialista</span>  <br />
                                    <span>{request.invitation.CommercialName}</span>
                                </p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgAddress} className="invitation-icon" />Dirección</span>  <br />
                                    <span>{request.invitation.Address}</span>
                                </p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgReference} className="invitation-icon" />Referencia Presupuesto</span>  <br />
                                    <span>{request.invitation.Reference && request.invitation.Reference !== "" ? request.invitation.Reference : "---"}</span>
                                </p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>

                                <p><span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgtreatments} className="invitation-icon" />Tratamientos</span>  <br />


                                    {request.invitation.Treatments && request.invitation.Treatments.length > 0 ?
                                        request.invitation.Treatments.map(t => {
                                            return <Badge key={t} pill bg="custom">
                                                {t}
                                            </Badge>
                                        })
                                        : <></>}</p>





                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgPatients} className="invitation-icon" />Pacientes</span>  <br />
                                    <span>{request.invitation.Patients}</span>
                                </p>

                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgtreatment} className="invitation-icon" />Valor del Tratamiento</span>  <br />
                                    <span>{request.invitation.LabelAmountTreatment}</span>
                                </p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgImpfin} className="invitation-icon" />Valor a Financiar</span>  <br />
                                    <span>{request.invitation.LabelAmountFinance}</span>
                                </p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgDay} className="invitation-icon" />Día Límite de Pago</span><br />
                                    <span>{tempPayDay ?? ""} {allData && <i style={{ color: "#00A9EB", cursor: "pointer" }} onClick={changePayDay} className="bi bi-pencil"></i>}</span>
                                </p>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <p>
                                    <span style={{ fontWeight: "bold", color: "#00A9EB" }}><img src={imgTerm} className="invitation-icon" />Plazo</span>

                                </p>

                            </td>
                        </tr>






                        {/* <tr>
                            <td colSpan={2} >
                                <h1>Invitación plan de pagos</h1>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className="form-floating">
                                    <input id="CommercialName" type="text" name="CommercialName" readOnly disabled value={request.invitation.CommercialName} className="form-control" placeholder="Aliado" autoComplete="off" />
                                    <label htmlFor="CommercialName">Aliado</label>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "center" }} colSpan={2}>
                                <img className='imgAddress' src={imgAddress} alt='imgAddress'></img>
                                <span style={{ color: "gray", fontSize: "15px" }}>{request.invitation.Address}</span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className="form-floating">
                                    <input id="ReferenceP" type="text" name="ReferenceP" readOnly disabled value={request.invitation.Reference} className="form-control" placeholder="Referencia Presupuesto" autoComplete="off" />
                                    <label htmlFor="ReferenceP">Referencia Presupuesto</label>
                                </div>

                            </td>
                        </tr>


                        <tr>

                            <td colSpan={2}>
                                <div className="form-floating">
                                    <input id="treatments" type="text" name="treatments" readOnly disabled value={request.invitation.Treatments && request.invitation.Treatments.length > 0 ? request.invitation.Treatments.join(", ") : ""} className="form-control" placeholder="Tratamientos" autoComplete="off" />
                                    <label htmlFor="treatments">Tratamientos</label>
                                </div>

                            </td>





                        </tr>

                        <tr>
                            <td>
                                <div className="form-floating">

                                    <MaskedInput mask={currencyMask} name="treatmentCost" value={form.treatmentCost} onChange={handleChange} placeholder="Costo del tratamiento" className={`form-control ${errors.treatmentCost && "is-invalid"}`} autoComplete="off" disabled={true}></MaskedInput >
                                    <label htmlFor="treatmentCost">Costo del tratamiento</label>
                                </div>

                            </td>
                            <td>
                                <div className="form-floating">

                                    <MaskedInput mask={currencyMask} name="loanAmount" value={form.loanAmount} onChange={handleChange} placeholder="Importe a financiar" className={`form-control ${errors.loanAmount && "is-invalid"}`} autoComplete="off" disabled={true}></MaskedInput >
                                    <label htmlFor="treatmentCost">Importe a financiar</label>
                                </div>
                               
                            </td>
                        </tr> */}


                        {showSkeleton ? (<tr>
                            <td colSpan={2}>
                                <Skeleton height={40} />
                            </td>
                            {/* <td>
                                <Skeleton height={40} />
                            </td> */}
                        </tr>) : (<tr>
                            <td className="td-input-invi" colSpan={2}>
                                {/* <CustomSelect name="term" value={form.term} options={terms} text="Plazo" error={errors.term} handleChangeSelect={handleChangeSelect} /> */}

                                <Container>

                                    <Row >
                                        {terms && terms.map((t, index) => {
                                            return <Col key={t.IdTerm} xs={12} sm={12} md={12} lg={terms.length % 2 !== 0 && index + 1 === terms.length ? 12 : 6} xl={terms.length % 2 !== 0 && index + 1 === terms.length ? 12 : 6} style={{ marginBottom: "10px" }}>
                                                <button onClick={() => {

                                                    setSelectedTerm(t.IdTerm);
                                                    setApplicationData({
                                                        InterestBase: t.LabelInterestBaseMonthly,
                                                        InterestBaseAnnual: t.LabelInterestBaseAnnual,
                                                        LabelRegularInstalment: t.LabelRegularInstalment,
                                                        FirstInstalmentDate: allData?.CusPayDayData?.FirstInstalmentDate,
                                                        LabelTerm: t.LabelTerm
                                                    })
                                                    handleChangeSelect({ target: { name: "term", value: t.Term } })
                                                    setTableAmortization(t.TableAmortization);
                                                    setIsSimulated(true);

                                                }} style={{ color: selectedTerm === t.IdTerm ? "white" : "rgb(0, 169, 235)", width: window.innerWidth >= 1200 && terms.length % 2 !== 0 && index + 1 === terms.length ? "50%" : "90%", backgroundColor: selectedTerm === t.IdTerm ? "rgb(0, 169, 235)" : "white" }} type="button" className="btn btn-mp" >{t.LabelTermBaseInstalment}</button>
                                            </Col>


                                        })}
                                    </Row>
                                </Container>
                            </td>

                            {/* <td className="td-input-invi">
                                <CustomSelect name="payDay" disabled={payDays.length === 0} value={form.payDay} options={payDays} text="Día de pago" error={errors.payDay} handleChangeSelect={handleChangeSelect} />

                            </td> */}
                        </tr>)}


                        {/* <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>
                                <button type="button" className="btn" style={{ backgroundColor: "#ff9e25", color: "white" }} onClick={simulate}>Calcular Plan de Pagos</button>
                            </td>
                        </tr> */}
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>
                                {
                                    applicationData &&
                                    <>
                                        <p style={{ color: "gray" }}>Tarifa de Financiación</p>
                                        <div style={{ textAlign: "center", backgroundColor: "#ededed", fontWeight: "bold", padding: "15px", borderRadius: "10px" }}>
                                            <p>
                                                {applicationData.InterestBase}    |    {applicationData.InterestBaseAnnual}
                                                <br />
                                                {applicationData.LabelRegularInstalment} / mes
                                                <br />
                                                {applicationData.FirstInstalmentDate} Primer pago
                                                <br />
                                                <u style={{ color: "#00A9EB", cursor: "pointer" }} onClick={handleShow}>Ver plan de pagos</u>
                                            </p>

                                        </div>
                                    </>

                                }
                            </td>
                        </tr>
                        <tr>

                            <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>
                                <Link to="/Dashboard" onClick={() => {
                                    setStorage("request", {
                                        ...request, state: 9
                                    })

                                }}>
                                    <button type="button" disabled={sending} className="btn btn-mp-back" style={{ marginRight: "20px" }}>Regresar</button>
                                </Link>

                                <input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form >


            <Modal show={show} onHide={handleClose} dialogClassName="modal-plan">
                <Modal.Header closeButton>
                    <Modal.Title>Plan de pagos</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    < table id="table-amortization">
                        <thead>
                            <tr>
                                <th>Num cuota</th>

                                <th>Fecha de pago</th>

                                <th>Capital inicial</th>

                                {/* <th>Cuota base</th> */}

                                <th>Capital</th>

                                <th>Interes</th>

                                <th>Comision</th>

                                <th>Seguro</th>

                                <th>Aval</th>

                                <th>Cuota total</th>

                                <th>Capital restante</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableAmortization.map((el) => {
                                    return (
                                        <React.Fragment key={el.NumInstalment}>
                                            <tr >
                                                <td data-label="Num cuota">
                                                    {el.NumInstalment}
                                                </td>
                                                <td data-label="Fecha de pago">
                                                    {el.InstallmentDate}
                                                </td>
                                                <td data-label="Capital inicial">
                                                    {el.InitialCapital}
                                                </td>
                                                {/* <td data-label="Cuota base">
                                                    {el.BaseInstalment}
                                                </td> */}
                                                <td data-label="Capital">
                                                    {el.Capital}
                                                </td>
                                                <td data-label="Interes">
                                                    {el.Interest}
                                                </td>
                                                <td data-label="Comision">
                                                    {el.OriginFee}
                                                </td>
                                                <td data-label="Seguro">
                                                    {el.Insurance}
                                                </td>
                                                <td data-label="Aval">
                                                    {el.Guarantee}
                                                </td>
                                                <td data-label="Cuota total">
                                                    {el.TotalInstalment}
                                                </td>
                                                <td data-label="Capital restante">
                                                    {el.RemainingCapital}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showModalPD} onHide={handleCloseModalPD} centered backdrop="static" dialogClassName="modal-payday" keyboard={false}>
                <Modal.Header>
                    <h3>Elige el día que más te convenga para realizar tus pagos</h3>
                </Modal.Header>

                <Modal.Body>
                    <div className="modal-payday-content" style={{ textAlign: "center", height: window.innerHeight - (window.innerHeight * 0.3) }}>

                        <span style={{ fontWeight: "bold", color: "#00A9EB", marginTop: "50px" }}><img src={imgDay} style={{ width: "80px" }} /></span><br />
                        <br />
                        <p>
                            Este día sera tu fecha limite de pago mensual y lo usaremos para <b>todos tus créditos con Meddipay</b>. Una vez seleccionado, <b>NO</b> será fácil de cambiar. ¡Elige con confianza y cuidado! 🧐
                        </p>
                        <br /> <br />
                        <CustomSelect name="payDay" disabled={payDays.length === 0} value={form.payDay} options={payDays} text="Día de pago" error={errors.payDay} handleChangeSelect={handleChangeSelect} />

                        <br />
                        <br />
                        <br />

                        <button type="button" disabled={sending} className="btn btn-mp-back" style={{ marginRight: "20px" }}
                            onClick={() => {
                                if (editPD) {
                                    setShowModalPD(false)
                                }
                                else {
                                    setStorage("request", {
                                        ...request, state: 9
                                    })
                                    navigate('/Dashboard', { replace: true });
                                }

                            }}>Regresar</button>


                        <input type="submit" value="Guardar" disabled={sending} className="btn btn-mp" onClick={savePayDay} />

                    </div>


                </Modal.Body>


            </Modal>



        </>

    );
}


const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    //   integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const currencyMask = createNumberMask(defaultMaskOptions)


export default RequestStepSix;