import React, { useEffect, useState } from "react";
import { useCreditRequest } from "../Context/useCreditRequest";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import { json, useNavigate } from "react-router";
import TarsServices from "../Services/TarsServices";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useForm from "../Hooks/useForm";
import '../StyleSheets/QuestionVerification.css'
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
import { Link } from "react-router-dom";
import { GenerateJWTSessionCustomer } from '../Services/SoraService';

const QuestionsVerification = () => {

    const { setLogoSize } = useCreditRequest();
    const [sending, setSending] = useState(false);
    const [questionsData, setQuestionsData] = useState(null);
    const navigate = useNavigate();
    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request")
    const MySwal = withReactContent(Swal);
    const { GetQuestions, SetAnswers } = TarsServices();
    const initialForm = {}

    const validateForm = (form) => {
        let errors = {};
        if (!form.pregunta1) { errors.pregunta1 = "El campo 1 es requerido" }
        if (!form.pregunta2) { errors.pregunta1 = "El campo 2 es requerido" }
        if (!form.pregunta3) { errors.pregunta1 = "El campo 3 es requerido" }
        if (!form.pregunta4) { errors.pregunta1 = "El campo 4 es requerido" }
        return errors;
    }
    const { form, errors, handleChange, handleSubmit, resetForm, } = useForm(initialForm, validateForm);

    useEffect(() => {
        getQuestions();

    }, []);


    const getQuestions = () => {
        setQuestionsData(null)
        setSending(true);
        GetQuestions({
            identificacion: request.cusDocument,
            "primerApellido": request.cusFirstSurname,
            "tipoIdentificacion": request.cusDocumentType,
            "segundoApellido": request.cusSecondSurname,
            "nombres": request.cusFirstName + " " + request.cusSecondName
        }).then(res => {
            setSending(false);

            if (res.status === 200) {

                let data = JSON.parse(res.data.data)



                if (data.resultado_validacion === "01" || data.resultado_validacion === "05") {
                    if (data.resultado_preguntas === "01") {
                        console.log("OK")
                    }
                    else if (data.resultado_preguntas === "10") {
                        MySwal.fire({
                            html: <ModalError msg={"Se han agotado el número de intentos permitidos por día"} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }
                    else if (data.resultado_preguntas === "11") {
                        MySwal.fire({
                            html: <ModalError msg={"Se han agotado el número de intentos permitidos por este mes"} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }
                    else if (data.resultado_preguntas === "12") {
                        MySwal.fire({
                            html: <ModalError msg={"Se han agotado el número de intentos permitidos por este año"} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }
                    else {

                        MySwal.fire({
                            html: <ModalError msg={"No se pudieron generar las preguntas para la identificación " + request.cusDocument} retry={false}></ModalError>,
                            icon: 'error',
                            showCancelButton: false,
                            showConfirmButton: false,
                            showDenyButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false

                        })
                    }

                }
                else if (data.resultado_validacion === "06") {

                    MySwal.fire({
                        html: <ModalError msg={"Nombres y/o apellidos no coinciden para la identificación " + request.cusDocument} retry={false} ></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "07") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "08") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "09") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
                else if (data.resultado_validacion === "11") {

                    MySwal.fire({
                        html: <ModalError msg={"Se generó un problema al validar la identificacion " + request.cusDocument} retry={false}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }

                setQuestionsData({ ...data, preguntas: data.preguntas.map(p => { let maxLength = 0; return { ...p, maxLength: Math.max(...p.respuestas.map(r => { return r.texto.length > maxLength ? r.texto.length : maxLength })) } }) })

            } else {

                MySwal.fire({
                    html: <ModalError msg={"Se generó un error"} retry={true}></ModalError>,
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    showDenyButton: false,
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false

                })
            }
        })
    }

    const submitForm = (form, error) => {
        setSending(true);

        let body = {
            identificacion: request.cusDocument,
            "tipoIdentificacion": request.cusDocumentType,
            "idCuestionario": questionsData.id,
            "regCuestionario": questionsData.registro,
            "respuestas": Object.entries(form).map(f => { return { idPregunta: f[0], idRespuesta: f[1] } })
        }

        SetAnswers(body).then(res => {

            if (res.status === 200) {
                let data = JSON.parse(res.data.data)

                if (data.aprobacion) {

                    GenerateJWTSessionCustomer({ token: data.token }).then(resjwt => {
                        setSending(false);
                        if (resjwt.status === 200 || resjwt.status === 201) {

                            setStorage("request", {
                                ...request,
                                state: 9,
                                identityValidated: true
                            });

                            setStorage("creditRequestToken", JSON.parse(resjwt.data.data).Token, { path: "/" })
                            setStorage("expiredTime", Date.parse(JSON.parse(resjwt.data.data).ExpiredTime), { path: "/" })

                            navigate("/Dashboard");

                        } else {

                            MySwal.fire({
                                html: "Error al generar el token",
                                icon: 'error'
                            })
                        }

                    })

                } else {
                    setSending(false);
                    MySwal.fire({
                        html: <ModalError msg={"Validación fallida"} retry={true}></ModalError>,
                        icon: 'error',
                        showCancelButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        allowEnterKey: false,
                        allowEscapeKey: false,
                        allowOutsideClick: false

                    })
                }
            } else {
                setSending(false);
                MySwal.fire({
                    html: <ModalError msg={"Se generó un error"} retry={true}></ModalError>,
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    showDenyButton: false,
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false

                })
            }




        })
    }

    const ModalError = ({ msg, retry }) => {


        return <>

            {msg}
            <br />
            <br />
            <table style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        {retry && (<td style={{ padding: "5px" }}>
                            <button type="button" style={{ width: "250px" }} className="btn btn-mp" onClick={() => { getQuestions(); MySwal.close() }}>Reintentar</button>
                        </td>)}
                    </tr>

                    <tr>
                        <td style={{ padding: "5px" }}>
                            <button type="button" style={{ width: "250px" }} className="btn btn-mp" onClick={() => {
                                window.open("https://wa.me/" + encodeURI("+573011633330"))
                                // MySwal.close();
                            }}>Contactar con servicio al cliente</button></td>
                    </tr>

                    {/* <tr>
                        <td style={{ padding: "5px" }}>
                            <button type="button" className="btn btn-mp" onClick={() => {
                                setStorage("request", {
                                    ...request,
                                    state: 5,
                                    allowSelfieVerification: false,
                                    allowManualVerification: true,
                                    allowQuestionsVerification: false
                                });
                                MySwal.close();
                                navigate("/Verification");
                            }}>Elegir otro metodo</button><br /></td>
                    </tr> */}

                    {/* <tr>
                        <td style={{ padding: "5px" }}>
                            <button type="button" className="btn btn-mp" onClick={() => {
                                setStorage("request", {
                                    ...request,
                                    state: 0,
                                    updateCustomer: true
                                });
                                MySwal.close();
                                navigate("/");
                            }}>Modificar datos</button></td>
                    </tr> */}

                </tbody>
            </table>


        </>

    }

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <table id="q-verification" >
                    <tbody>

                        <tr>
                            <td style={{ textAlign: "center", color: "black", fontSize: "13px", padding: "0px" }} colSpan={2}>
                                {request.cusFirstName} {request.cusSecondName} {request.cusFirstSurname} {request.cusSecondSurname}

                            </td>

                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", color: "gray", fontSize: "13px", padding: "0px" }} colSpan={2}>

                                {request.cusDocumentTypeTemp}   {request.cusDocument}
                            </td>

                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", color: "gray", paddingTop: "15px" }} colSpan={2}>
                                <h3><b style={{ color: "black" }}>4</b>/4</h3>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", fontSize: "18px", padding: "0px", fontWeight: "bold" }} >
                                Por motivos de seguridad, a continuación te solicitamos responder varias preguntas con el fin de validar tu identidad
                                <br />
                                <br />
                            </td>
                        </tr>
                        {questionsData && questionsData.preguntas && (questionsData.preguntas.map(q => {

                            return <React.Fragment key={q.id}>
                                <tr>
                                    <td >
                                        <p>{q.texto}</p>
                                        <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td >
                                        <div className="container-fluid">
                                            <div className="row" style={{ textAlign: "center" }}>
                                                {
                                                    q.respuestas.map(r => {
                                                        return <React.Fragment key={r.id}>


                                                            <div className="col" key={r.id} style={{ marginBottom: "10px" }}>
                                                                <input type="radio" className="btn-check" name={q.id} id={"pregunta" + q.id + "_respuesta" + r.id} value={r.id} onChange={handleChange} autoComplete="off" required />
                                                                <label style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: q.maxLength > 50 && window.innerWidth < 640 ? "150px" : q.maxLength > 50 ? "120px" : q.maxLength > 30 && window.innerWidth < 640 ? "150px" : q.maxLength > 30 ? "120px" : q.maxLength > 20 ? "80px" : "50px" }} className="btn btn-outline-secondary" htmlFor={"pregunta" + q.id + "_respuesta" + r.id}>{r.texto}</label>
                                                            </div>



                                                        </React.Fragment>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <br />     <br />
                                    </td>
                                </tr>
                            </React.Fragment>
                        }))}
                        <tr>

                            <td style={{ textAlign: "center", padding: "10px" }} colSpan={2}>
                                <Link to="/IdentityValidation" onClick={() => {
                                    setStorage("request", {
                                        ...request, state: 14
                                    })
                                }}>
                                    <button type="button" disabled={sending} className="btn btn-mp-back" style={{ marginRight: "20px" }}>Regresar</button>
                                </Link>

                                <input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </form>



        </>

    );
}

export default QuestionsVerification;