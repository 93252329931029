import axios from "axios";
import { OlimpiaApi } from "../Config";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

const OlimpiaServices = () => {

    const { getStorage } = useCreditRequestAuth();
    let creditRequestToken = getStorage("creditRequestToken")

    const GetUrlIFrame = async (cusDocumentType, cusDocument, cusEmail, phoneCodeTemp, cusPhone) => {
        phoneCodeTemp = phoneCodeTemp.replace("+", "");
        let respose = await axios.get(OlimpiaApi("ReconoserID/GetUrlIFrame?tipoDocumento=" + cusDocumentType + "&numeroDocumento=" + cusDocument + "&email=" + encodeURIComponent(cusEmail) + "&prefijo=" + phoneCodeTemp + "&celular=" + cusPhone),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {

                return { error: 1 }
            })

        return respose;

    };

    const SendUrlToDestination = async (IdCustomer, destinationType) => {

        let respose = await axios.get(OlimpiaApi("ReconoserID/SendUrlToDestination?destinationType=" + destinationType),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((res) => {

                return res;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response.data; // => the response payload 
                }
                return { error: 1 }
            })

        return respose;

    };

    const ConsultarEstadoValidacionProceso = async (guid) => {

        let respose = await axios.get(OlimpiaApi("ReconoserID/ConsultarEstadoValidacionProceso?GuidProceso=" + guid),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                return { error: 1 }
            })

        return respose;
    };

    const CrearProcesoPorIdCredito = async (IdApp) => {

        let respose = await axios.get(OlimpiaApi("MisPagares/CrearProcesoPorIdCredito?IdApp=" + IdApp),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const ValidarOTP = async (body) => {

        let respose = await axios.post(OlimpiaApi("MisPagares/ValidarOTP"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const ReenviarOTP = async (body) => {

        let respose = await axios.post(OlimpiaApi("MisPagares/ReenviarOTP"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const GetSignedDocuments = async (body) => {

        let respose = await axios.post(OlimpiaApi("MisPagares/GetSignedDocumentsCHAN"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    return {
        GetUrlIFrame, ConsultarEstadoValidacionProceso, SendUrlToDestination, CrearProcesoPorIdCredito
        , ValidarOTP, ReenviarOTP, GetSignedDocuments
    }
}

export default OlimpiaServices;