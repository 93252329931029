import * as React from "react";
import '../StyleSheets/AppLogin.css'
import logo from '../Images/logo_whitegray.svg'
import ReCAPTCHA from 'react-google-recaptcha';
import { GetToken } from "../Services/SoraService";
import { Navigate } from "react-router-dom";

const recaptchaRef = React.createRef();

const result = {
    result: '',
    responseText: '',
    data: ''
}

const credential = {
    user: '',
    password: ''
}

class AppLogin extends React.Component {
    constructor() {
        super();
        this.state = {
            stResult: result,
            stCredentials: credential
        }
        this.onChange = this.onChange.bind(this);
    }

    onSubmit() {
        recaptchaRef.current.execute();
    }

    async onChange(value) {
        if (value) {
            const resultAPI = await this.validateCredentials();
            this.setState({
                stResult: resultAPI
            });
            if (resultAPI.result == 200) {
                localStorage.setItem('token', resultAPI.data.token);
                localStorage.setItem('refresh', resultAPI.data.refreshToken);
                localStorage.setItem('user', resultAPI.data.emailId);
            }
        }
    }

    async validateCredentials() {
        return await await GetToken({
            userName: this.state.stCredentials.user,
            password: this.state.stCredentials.password,
            app: 'CHAN'
        });
    }

    updateInputUser(e) {
        this.setState({
            stCredentials: { user: e.target.value, password: this.state.stCredentials.password },
            stResult: result
        });
    }

    updateInputPassword(e) {
        this.setState({
            stCredentials: { user: this.state.stCredentials.user, password: e.target.value },
            stResult: result
        });
    }

    render() {
        const { stResult, stCredentials } = this.state;
        return (
            <div className='containerPrincipalLogin'>
                {stResult.result == 200 && (
                    <Navigate to="/Autenticado" replace={true} />
                )}
                <div className='divisorLogin'>&nbsp;</div>
                <div className='containerImagenLogin'>
                    <img src={logo} className='imagenMeddiPayLogin'></img>
                </div>
                <div className='containerLoginLogin'>
                    <input className='textUsuarioLogin' type='text' placeholder='Coreo Electrónico' name='txtCorreo' value={this.state.stCredentials.user} onChange={e => this.updateInputUser(e)} />
                    <input className='textUsuarioLogin' type='password' placeholder='Contraseña' name='txtContrasena' value={this.state.stCredentials.password} onChange={e => this.updateInputPassword(e)} />
                    <span className="textDangerLogin">{stResult.result !== 200 ? stResult.responseText : ''}</span>
                    <p className='olvidoRefLogin'> <a href="/RequestChangePassword">¿Olvidó su contraseña?</a> </p>
                    <div className='capchaLogin'>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LdlOiMlAAAAAPtB2K6kxvszryfyhJ1h6q0n2DKB"
                            onChange={this.onChange} />
                    </div>
                    <button className="butonAccederLogin" type="submit" onClick={() => this.onSubmit()}>
                        <b>Acceder</b>
                    </button>
                </div>
            </div>);
    }
}

export default AppLogin;