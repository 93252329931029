import '../StyleSheets/ModalCreditRequest.css';

const ModalCreditRequest = ({ children, isOpen, closeModal }) => {

    const handleContainerClick = (e) => { e.stopPropagation(); };
    return (
        <div className={`my-modal ${isOpen && "is-open"}`} onClick={closeModal}>

            <table className="my-modal-container" onClick={handleContainerClick}>
                <tbody>
                    <tr>
                        <td>
                            {children}
                        </td>
                    </tr>
                    {/* <tr>
                        <td>
                            <button style={{backgroundColor:"#79d853"}} className="my-modal-close btn btn-light" onClick={closeModal}>Aceptar</button>
                        </td>
                    </tr> */}
                </tbody>

            </table>



        </div>
    );
}

export default ModalCreditRequest;