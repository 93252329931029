import React from "react";
import '../StyleSheets/ResposeChangePassword.css'
import logo from '../Images/logo_whitegray.svg'
import { getVerifyTokenChangePassword } from "../Services/SoraService";
import { ResetPasswordToken } from "../Services/SoraService";

const passwordState = {
    password1: '',
    password2: '',
    error: ''
}

const result = {
    result: '',
    responseText: '',
    data: ''
}

class ResposeChangePassword extends React.Component {
    constructor() {
        super();
        this.state = {
            stResult: result,
            stPassword: passwordState
        }
        this.onChangeP1 = this.onChangeP1.bind(this);
        this.onChangeP2 = this.onChangeP2.bind(this);
    }

    onChangeP1(e) {
        this.setState({
            stPassword: { password1: e.target.value, password2: this.state.stPassword.password2, error: this.state.stPassword.error }
        });
    }
    onChangeP2(e) {
        this.setState({
            stPassword: { password2: e.target.value, password1: this.state.stPassword.password1, error: this.state.stPassword.error }
        });
    }

    async onSubmit(token) {
        if (this.passwordValidation()) {
            const result = await this.validateToken(token);
            if (result.result == 200) {
                let user = JSON.parse(result.data);
                this.setState({
                    stResult: await this.changePassword(token, user.UserName),
                    stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: this.state.stPassword.error }
                });
            }
            else if (result.responseText?.toLowerCase().includes('expired')) {
                this.setState({
                    stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: 'Tiempo agotado para el cambio de contraseña. Por favor realice una nueva solicitud.' }
                });
            }
            else if (result.responseText?.toLowerCase().includes('invalid')) {
                this.setState({
                    stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: 'Ya existe un cambio de contraseña previa a esta solicitud.' }
                });
            }
            else {
                this.setState({
                    stResult: result,
                    stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: '' }
                });
            }
        }
    }

    async validateToken(token) {
        return await getVerifyTokenChangePassword({ token: token })
    }

    async changePassword(token, userName) {
        return await ResetPasswordToken({
            token: token,
            Password: this.state.stPassword.password1,
            UserName: userName,
            IdApp: 'CHAN'
        })
    }

    passwordValidation() {
        if (!this.state.stPassword.password1 || !this.state.stPassword.password1.trim()) {
            this.setState({
                stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: "Digite contaseña." }
            });
            return false;
        }
        else if (!this.state.stPassword.password2 || !this.state.stPassword.password2.trim()) {
            this.setState({
                stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: "Confirme contaseña." }
            });
            return false;
        }
        else if (this.state.stPassword.password1.trim() !== this.state.stPassword.password2.trim()) {
            this.setState({
                stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: "Las contraseñas no son concidentes." }
            });
            return false;
        }
        this.setState({
            stPassword: { password1: this.state.stPassword.password1, password2: this.state.stPassword.password2, error: "" }
        });
        return true;
    }


    render() {
        const { stPassword, stResult } = this.state;
        const params = new URLSearchParams(window.location.search);
        const token = params.get("token");
        return (
            <div className='containerPrincipalChangePwResp'>
                <div className='containerImagenChangePwResp'>
                    <img src={logo} className='imagenMeddiPayChangePwResp'></img>
                </div>
                <div className='containerOtrosChangePwResp'>
                    <input className="textPasswordChangePwResp" type="password" name="password1" placeholder='Escriba su contraseña' value={this.state.stPassword.password1} onChange={this.onChangeP1} />
                    <input className="textPasswordChangePwResp" type="password" name="password2" placeholder='Confirme su contraseña' value={this.state.stPassword.password2} onChange={this.onChangeP2} />
                    <span className="textDangerChangePwResp">{stPassword.error}</span>
                    <button className="buttonRestablecerChangePwResp" type="submit" onClick={() => this.onSubmit(token)}>
                        Restablecer
                    </button>
                    <div className='containerRespuestaChangePwResp'>
                        <p>{stResult.result == '200' ? 'Se ha realizado el cambio de contraseña,' : ''}</p>
                        <p>{stResult.result == '200' ? 'Podemos dirigirte al login?' : ''}</p>
                        <p className='redirigirRefChangePwResp'> <a href="/login">{stResult.result == '200' ? 'Ir al login' : ''}</a> </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResposeChangePassword;