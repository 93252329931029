import React from "react";

const CustomSelect = React.forwardRef((props, ref) => {

    const { name, error, handleChangeSelect, text, options, value, disabled = false } = props

    return <div className="form-floating">
        <select ref={ref} name={name} className={`form-control form-select ${error && "is-invalid"}`} id={name} value={value} onChange={handleChangeSelect} disabled={disabled}>
            <option disabled value="" style={{ display: "none", visibility: "hidden" }}></option>
            {options.map(it => {
                return <option key={it.value} value={it.value}>{it.name}</option>
            })}


        </select>
        <label htmlFor={name} className={value ? "floating-label-ro2" : "floating-label-ro1"}>{text}</label>
    </div>
})


export default CustomSelect;