import React from "react";

function UserAutenticate() {
    return (
        <div>
            <br/>
            <p><b>Usuario ya autenticado, formulario de prueba.</b></p>
            <div>
                Token: {localStorage.getItem('token')}
            </div>
            <div>
                RefreshToken: {localStorage.getItem('refresh')}
            </div>
            <div>
                User: {localStorage.getItem('user')}
            </div>
        </div>
    );
}

export default UserAutenticate;