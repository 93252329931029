import axios from "axios";
import { BellApi } from "../Config";
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";

const BellServices = () => {

    const { getStorage } = useCreditRequestAuth();

    let creditRequestToken = getStorage("creditRequestToken")


    const GenerateOTPAndSendMessage = async (body) => {

        let respose = await axios.post(BellApi("Messages/GenerateOTP"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const GenerateOTPAndSendMessageEmail = async (body) => {

        let respose = await axios.post(BellApi("EMail/GenerateOTP"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const GenerateOTPAndSendMessageWhatsApp = async (body) => {

        let respose = await axios.post(BellApi("WhatsApp/GenerateOTP"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const ValidateOTP = async (phone, OTP, region, lang) => {
        let respose = await axios.get(BellApi("Messages/ValidateOTP?Destination=" + encodeURIComponent(phone) + "&CodeOTP=" + OTP + "&Region=" + region + "&Lang=" + lang),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const ValidateWhatsAppOTP = async (phone, OTP, region, lang) => {
        let respose = await axios.get(BellApi("WhatsApp/ValidateOTP?Destination=" + encodeURIComponent(phone) + "&CodeOTP=" + OTP + "&Region=" + region + "&Lang=" + lang),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    const ValidateEmailOTP = async (email, OTP, region, lang) => {
        let respose = await axios.get(BellApi("EMail/ValidateOTP?Destination=" + encodeURIComponent(email) + "&CodeOTP=" + OTP + "&Region=" + region + "&Lang=" + lang),
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };


    const SendTemplateWhatsApp = async (body) => {

        let respose = await axios.post(BellApi("WhatsApp/SendTemplate"),
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    "Authorization": "Bearer " + creditRequestToken
                }
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {

                if (error.response) {
                    return error.response; // => the response payload 
                }
                return { status: 500, data: { message: "ERROR" } }
            })

        return respose;

    };

    return {
        GenerateOTPAndSendMessage, GenerateOTPAndSendMessageEmail, ValidateOTP, ValidateEmailOTP,
        GenerateOTPAndSendMessageWhatsApp, ValidateWhatsAppOTP, SendTemplateWhatsApp
    }
}

export default BellServices;