import { useState } from 'react';


const useForm = (initialForm, validateForm) => {

    const [form, setForm] = useState(initialForm);
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        let { name, value } = e.target;

        setForm(form => ({ ...form, [name]: value }));

        if (value) {
            setErrors({ ...errors, [name]: undefined })
        }
    };

    const handleChangeOnlyNumbers = e => {
        let { name, value } = e.target;

        value = value.replace(/[^\w\s]/gi, "").replace(/[a-z]/gi, '').replace(" ", "")
        value = value.replace(/^0+/, '')

        setForm(form => ({ ...form, [name]: value }));

        if (value) {
            setErrors({ ...errors, [name]: undefined })
        }
    };

    const handleChangeDate = e => {
        const { name, value } = e.target;

        if (name === "day" && value.length === 2) {
            let field1 = document.querySelector("input[name=month]");
            field1.focus();
        }
        if (name === "month" && value.length === 2) {
            let field2 = document.querySelector("input[name=year]");
            field2.focus();
        }
        setForm(form => ({ ...form, [name]: value }));

        if (value) {
            setErrors({ ...errors, [name]: undefined })
        }
    };

    const handleChangeSelectWithName = e => {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        const { name, value } = e.target;
        setForm(form => ({ ...form, [name]: value, [name + "Temp"]: { value: value, name: label } }));

        if (value) {
            setErrors({ ...errors, [name]: undefined })
        }
    };

    const handleChangeSelect = e => {
      
        const { name, value } = e.target;
        setForm(form => ({ ...form, [name]: value }));

        if (value) {
            setErrors({ ...errors, [name]: undefined })
        }


    };

    const handleChangeOtp = (e) => {

        let { name, value } = e.target;


        if (value.length > 1) {

            if (value.length === 6) {
                handleChangeOtp({ target: { name: "digit1", value: value[0] } })
                handleChangeOtp({ target: { name: "digit2", value: value[1] } })
                handleChangeOtp({ target: { name: "digit3", value: value[2] } })
                handleChangeOtp({ target: { name: "digit4", value: value[3] } })
                handleChangeOtp({ target: { name: "digit5", value: value[4] } })
                handleChangeOtp({ target: { name: "digit6", value: value[5] } })
                return;
            }

            value = value.slice(1, 2);


        }

        let nextIndex = Number(name.slice(5, 6)) + 1;


        //este if es solo para poner el focus en el siguiente input
        if (nextIndex <= 6 && value.length === 1) {
            let nextfield = document.querySelector(
                `input[name=digit${nextIndex}]`
            );
            nextfield.focus();

        }


        setForm(form => ({ ...form, [name]: value }));

        if (value) {
            setErrors({ ...errors, [name]: undefined })
        }

    }

    const handleChangeOtpSign = (e) => {
        let { name, value } = e.target;


        if (value.length > 1) {

            if (value.length === 4) {
                handleChangeOtpSign({ target: { name: "digit1", value: value[0] } })
                handleChangeOtpSign({ target: { name: "digit2", value: value[1] } })
                handleChangeOtpSign({ target: { name: "digit3", value: value[2] } })
                handleChangeOtpSign({ target: { name: "digit4", value: value[3] } })
                return;
            }

            value = value.slice(0, 1);
        }

        let nextIndex = Number(name.slice(5, 6)) + 1;

        if (nextIndex <= 4 && value.length === 1) {
            let nextfield = document.querySelector(
                `input[name=digit${nextIndex}]`
            );
            nextfield.focus();

        }
        setForm(form => ({ ...form, [name]: value }));



    }

    const handleChangeTypeahead = (e, name) => {

        setForm(form => ({ ...form, [name]: e }));
    }

    const handleBlur = e => {
        // handleChange(e);
        // setErrors(validateForm(form));
    };

    const handleBlurTypeahead = (selected, name, ref) => {

        if (selected.length === 0) {
            handleChangeTypeahead([], name)
            ref.current.clear()
        }
    }

    const handleChecked = e => {
        setForm(form => ({ ...form, [e.target.name]: e.target.checked }))
    };

    const handleSubmit = (e, submitForm) => {

        e.preventDefault();

        let tempErrors = validateForm(form)

        setErrors(tempErrors);

        submitForm(form, Object.keys(tempErrors).length !== 0);

    };

    const resetForm = (f) => {
        setForm(f);
    }

    return {
        form, errors,setErrors, handleChange, handleChangeOtp, handleBlur, handleChecked, handleSubmit, resetForm, handleBlurTypeahead,
        handleChangeTypeahead, handleChangeOtpSign, handleChangeSelect, handleChangeSelectWithName, handleChangeDate,
        handleChangeOnlyNumbers
    };
}

export default useForm;