import React from 'react';
import '../StyleSheets/RequestChangePassword.css'
import logo from '../Images/logo_whitegray.svg'
import { getChangePasswordRequest } from "../Services/SoraService";

const emailState = {
    email: '',
    error: ''
}

const result = {
    result: '',
    responseText: '',
    data: ''
}

class RequestChangePassword extends React.Component {
    constructor() {
        super();
        this.state = {
            stResult: result,
            stEMail: emailState
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            stEMail: { email: e.target.value, error: this.state.stEMail.error }
        });
    }

    emailValidation() {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!this.state.stEMail.email || regex.test(this.state.stEMail.email) === false) {
            this.setState({
                stEMail: { error: "Correo electrónico no es válido.", email: this.state.stEMail.email }
            });
            return false;
        }
        return true;
    }

    async onSubmit() {
        if (this.emailValidation()) {
            this.setState({
                stResult: await getChangePasswordRequest({ email: this.state.stEMail.email, urlBase: `${window.location.protocol}//${window.location.host}` }),
                stEMail: { error: "", email: this.state.stEMail.email }
            });
        }
    }

    render() {
        const { stEMail, stResult } = this.state;
        return (
            <div className="containerPrincipalChangePwReq">
                <div className='containerImagenChangePwReq'>
                    <img src={logo} className='imagenMeddiPayChangePwReq'></img>
                </div>
                <div className='containerOtrosChangePwReq'>
                    <input className="textEmailChangePwReq" type="email" name="email" placeholder='Coreo Electrónico' value={stEMail.email} onChange={this.onChange} />
                    <span className="textDangerChangePwReq">{stEMail.error}</span>
                    <button className="buttonEnviarChangePwReq" type="submit" onClick={() => this.onSubmit()}>
                        Enviar solicitud
                    </button>
                    <div className='containerResposeOkChangePwReq'>
                        <p>{stResult.result == '200' ? 'Se envio un correo electrónico a tu cuenta de correo,' : ''}</p>
                        <p>{stResult.result == '200' ? 'revisa tu bandeja de entrada.' : ''}</p>
                        <p className='olvidoRefChangePwReq'> <a href="/">{stResult.result == '200' ? 'Regresa al home' : ''}</a> </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestChangePassword;